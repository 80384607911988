import { IModelProperty } from "@/utils/models/model-field";

export enum CalculatedFieldReturnType {
	BOOL = "boolean",
	NUMBER = "number",
	STRING = "string",
	DATE = "date",
	DURATION = "duration",
}

export class CalculatedField {
	ID:number = 0;
	Table:string = "";
	Name:string = "";
	JsCode:string = "";
	ReturnType:CalculatedFieldReturnType = CalculatedFieldReturnType.BOOL;
	Preloads:string[] = [];
	DisplayOrder:number = 0;

	public constructor(data:any){
		this.ID = data.ID;
		this.Table = data.Table;
		this.Name = data.Name;
		this.JsCode = data.JsCode;
		this.ReturnType = data.ReturnType;
		this.Preloads = [...(data.Preloads || [])];
		this.DisplayOrder = 0;
	}

	public toModelProperty():IModelProperty{
		return {
			type: this.ReturnType,
			modelName: this.Table,
			isComputed: true,
			filterOnly: false,
			rightAlign: this.ReturnType == CalculatedFieldReturnType.NUMBER,
			dependencies: this.Preloads,
			getTranslatedName: ()=>this.Name,
			notFilterable: true,
			isCalculatedField: true,
			getValueFunc: new Function("val", `${this.JsCode}`) as (this:any)=>any,
		};
	}

}