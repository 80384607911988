import { i18n } from "@/setup/i18n-setup";
import { PropSelectGroup } from "./prop-select-group";
import { PropSelectItem } from "./prop-select-item";

let fields = [
	"ID",
	"NR",
	"FirstName",
	"LastName",
	"FullName",
	"FullNameReverse",
	"CompanyOrFullName",
	"CompanyOrLastName",
	"CompanyOrFullNameReverse",
	"PostalAndCity",
	"CompanyName",
	"AddressLine1",
	"AddressLine2",
	"Number",
	"Box",
	"Postal",
	"City",
	"Email",
	"Phone",
	"Mobile",
	"Fax",
	"Lng",
	"CountryCode",
	"CountryName",
	"Reference"
];

export function getContactAddressMapPropSelectGroup():PropSelectGroup{
	let result = new PropSelectGroup("", "", "");
	for (let field of fields){
		result.items.push(new PropSelectItem(i18n.t(`models.map.ContactAddress.${field}`), field));
	}
	return result;
}