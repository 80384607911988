/* eslint-disable max-lines */
import ProductCompositionRow from "./product-composition-row";
import ProductExtraFields from "./product-extra-fields";
import { ProductImage } from "@/models/base";
import { Vat } from "@/models/base";
import { i18n } from "@/setup/i18n-setup";
import { CustomTranslation } from "@/utils/custom-translation";
import { ProductPriceRule } from "./product-price-rule";
import {
	printHumanFloat,
	printLocalDate,
	printHumanFloatPercentage,
	printBoolean,
	printHumanPurchaseFloat,
	printUser,
	printWithoutHtmlTags
} from "@/utils/pretty-print";
import { ProductTotal } from "@/utils/product-row";
import { ProductSupplier } from "@/utils/product-supplier";
import ProductStock from "./product-stock";
import ProductWriteOffRow from "./product-write-off-row";
import SaleRow from "./sale-row";
import { field, nullable, print, hidden, rightAlign, dependencies, filterValueInput, filterOnly, computed, decimals, canBeFilteredNummerically, onClick } from "@/utils/models/decorator-field";
import { priorityFields, model, namedFilters, ignoredFields } from "@/utils/models/decorator-model";
import { ProductGroupProduct } from "./product-group-product";
import { HumanFilter } from "@/utils/human-filter";
import { UserConfigService } from "@/services/user-config-service";
import { VatService } from "@/services/vat-service";
import { DossierService } from "@/services/dossier-service";
import { Unit } from "../unit";
import { NamedFilter } from "@/utils/models/named-filter";
import { LedgerAccount } from "../ledger-account";
import { DossierConfigService } from "@/services/dossier-config-service";
import { TranslateResult } from "vue-i18n";
import { ProductAwsDocument } from "./prolduct-aws-document";
import { ProductGroupModel } from "./product-group-model";
import { ProductPriceCategorySalesDates } from "./product-price-category-sales-dates";
import { StockLocationConfig } from "../dossier-configs/stock-location";
import { ProductService } from "@/services";
import { ProductIntrastatData } from "./product-intrastat-data";
import { AddToAutomaticOrderToSupplier } from "../dossier-configs/stock-location-settings";
import { getOpenAwsDocumentsFunc } from "@/utils/models/click-action-handlers";
import { AwsDocument } from "./aws-document";
import { ProductLabelSettings } from "./product-label-settings";
export const ProductConfig = {
	sellPriceCount: 10,
	buyPriceCount: 10,
};

@model("Product")
@priorityFields(["Sku", "Name"])
@namedFilters([
	new NamedFilter({
		name: "product-in-groups",
		filterToString(filter:HumanFilter):TranslateResult{
			if (filter.Options.indexOf("not-in") != -1){
				return i18n.t("models.friendlyNames.Product.product-not-in-groups-text");
			}
			return i18n.t("models.friendlyNames.Product.product-in-groups-text");
		}
	}),
	new NamedFilter({
		name: "amount-to-be-ordered",
		filterToString(filter:HumanFilter):TranslateResult{
			let amount = "";
			if (filter.Values.length > 0 && filter.Values != null){
				amount = filter.Values[0];
			}
			let operator = i18n.t("common.filters." + HumanFilter.getTranslationOperator(filter.Operator)).toString();
			return i18n.t("models.friendlyNames.Product.amount-to-be-ordered-text", {amount, operator});
		}
	})
])
@ignoredFields([
	"MainGroup.ParentGroup.ParentGroup",
	"MainSupplier.Contact.MainAddress.Contact"
])
export default class Product {
	@field("number")
	@filterValueInput("Product")
	@filterOnly()
	public ID:number = 0;

	@field("boolean")
	@print(printBoolean)
	public Active:boolean = true;

	@field("boolean")
	@computed()
	public get Disabled():boolean {
		return !this.Active;
	};

	@field("string")
	@print(printWithoutHtmlTags)
	@hidden()
	public Name:string = "";

	@field("string")
	@print(printWithoutHtmlTags)
	public NameStripped:string = "";

	@field("json")
	@hidden()
	public TranslatedName:CustomTranslation = new CustomTranslation();

	@field("string")
	@computed()
	public get NameNlBe():string {
		if(this.TranslatedName){
			return this.TranslatedName.getTranslationWithoutFallback("nl-be");
		}
		return "";
	}

	@field("string")
	@computed()
	public get NameFR():string {
		if(this.TranslatedName){
			return this.TranslatedName.getTranslationWithoutFallback("fr");
		}
		return "";
	}

	@field("string")
	@computed()
	public get NameEN():string {
		if(this.TranslatedName){
			return this.TranslatedName.getTranslationWithoutFallback("en");
		}
		return "";
	}

	@field("string")
	@computed()
	public get NameDE():string {
		if(this.TranslatedName){
			return this.TranslatedName.getTranslationWithoutFallback("de");
		}
		return "";
	}

	@field("string")
	@nullable()
	public Sku:string | null = null;

	@field("date")
	@print(printLocalDate)
	public TimeCreated:Date = new Date();

	@field("date")
	@print(printLocalDate)
	public TimeChanged:Date = new Date();

	@field("number")
	public VatID:number = VatService.getDefaultVat().ID;

	@field("has-one", "Vat")
	@nullable()
	public Vat:Vat | null = null;

	@field("has-many", "ProductSupplier")
	public Suppliers:ProductSupplier[] = [];

	@field("number")
	public MainSupplierID:number = 0;

	@field("has-one", "ProductSupplier")
	public MainSupplier:ProductSupplier | null = null;

	@field("number")
	@nullable()
	@print((val)=>printHumanPurchaseFloat(val))
	@rightAlign()
	public BuyNettoPrice:number | null = null;

	@field("number")
	@nullable()
	@print((val)=>printHumanPurchaseFloat(val))
	@rightAlign()
	public BuyCatalogPrice:number | null = null;

	@field("string")
	@nullable()
	public ManufacturerCode:string | null = null;

	@field("string")
	@nullable()
	public SupplierCode:string | null = null;

	@field("number")
	@rightAlign()
	@print((value:string)=>`${printHumanFloat(value)}%`)
	public RecupelTax:number = 0;

	@field("number")
	@rightAlign()
	@print((value:string)=>`${printHumanFloat(value)}%`)
	public AuvibelTax:number = 0;

	@field("number")
	@rightAlign()
	@print((value:string)=>`${printHumanFloat(value)}%`)
	public BebatTax:number = 0;

	@field("number")
	@rightAlign()
	@print((value:string)=>`${printHumanFloat(value)}%`)
	public ReprobelTax:number = 0;

	@field("number")
	@rightAlign()
	@print((value:string)=>`${printHumanFloat(value)}`)
	public LeeggoedTax:number = 0;

	@field("number")
	@rightAlign()
	@print((value:string)=>printHumanFloat(value))
	public AccijnzenTax:number = 0;

	@field("number")
	@rightAlign()
	@print((value:string)=>printHumanFloat(value))
	public EcoboniTax:number = 0;

	@field("number")
	@rightAlign()
	@print((value:string)=>`${printHumanFloat(value)}%`)
	public FostplusTax:number = 0;

	@field("boolean")
	@print(printBoolean)
	public ComputeStock:boolean = true;

	@field("has-many", "ProductCompositionRow")
	public Composition:ProductCompositionRow[] = [];

	@field("boolean")
	public BuyPriceComesFromComposition:boolean = false;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice1:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice2:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice3:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice4:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice5:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice6:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice7:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice8:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice9:number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice10:number = 0;

	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice1():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice1;
		}
		return this.SellPrice1 - this.BuyCatalogPrice;
	}

	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice2():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice2;
		}
		return this.SellPrice2 - this.BuyCatalogPrice;
	}

	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice3():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice3;
		}
		return this.SellPrice3 - this.BuyCatalogPrice;
	}

	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice4():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice4;
		}
		return this.SellPrice4 - this.BuyCatalogPrice;
	}


	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice5():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice5;
		}
		return this.SellPrice5 - this.BuyCatalogPrice;
	}

	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice6():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice6;
		}
		return this.SellPrice6 - this.BuyCatalogPrice;
	}


	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice7():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice7;
		}
		return this.SellPrice7 - this.BuyCatalogPrice;
	}

	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice8():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice8;
		}
		return this.SellPrice8 - this.BuyCatalogPrice;
	}


	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice9():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice9;
		}
		return this.SellPrice9 - this.BuyCatalogPrice;
	}

	@field("number")
	@computed()
	@decimals(2)
	@rightAlign()
	public get ProfitPrice10():number {
		if (!this.BuyCatalogPrice) {
			return this.SellPrice10;
		}
		return this.SellPrice10 - this.BuyCatalogPrice;
	}


	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage1():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice1 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage2():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice2 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage3():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice3 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage4():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice4 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage5():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice5 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage6():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice6 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage7():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice7 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage8():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice8 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage9():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice9 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloatPercentage(val))
	@rightAlign()
	public get ProfitPercentage10():number {
		if (!this.BuyNettoPrice) {
			return 0;
		}
		return (this.SellPrice10 - this.BuyNettoPrice) / this.BuyNettoPrice * 100;
	}

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice1WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice2WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice3WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice4WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice5WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice6WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice7WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice8WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice9WithVat: number = 0;

	@field("number")
	@decimals(2)
	@rightAlign()
	public SellPrice10WithVat: number = 0;

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice1WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(1);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice2WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(2);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice3WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(3);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice4WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(4);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice5WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(5);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice6WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(6);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice7WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(7);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice8WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(8);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice9WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(9);
	}

	@field("number")
	@computed()
	@print(val=>printHumanFloat(val))
	@rightAlign()
	public get SellPrice10WithTaxesAndVat():number {
		return this.getSellPriceWithVatAndTax(10);
	}



	@field("number")
	@rightAlign()
	@decimals(2)
	@dependencies(["Composition"])
	public get CompositionTotalExcVAT():number{
		if (this.Composition.length == 0) {
			return 0;
		}
		let allPrices = this.Composition.map((row) => row.getTotalPrice());
		return allPrices.reduce((sum: number, current: number) => sum + current);
	}

	@field("number")
	@computed()
	@rightAlign()
	@decimals(2)
	@dependencies(["Composition"])
	public get CompositionTotalVAT():number{
		if (this.Composition.length == 0) {
			return 0;
		}
		return this.Composition.map((row: ProductCompositionRow): number => {
			let vat = row.Vat / 100.0;
			let unitVat = ((row.UnitPrice) - (row.UnitPrice * (row.Discount / 100))) * vat;
			return unitVat * row.Amount;
		}).reduce((sum: number, current: number) => sum + current);
	}

	@field("string")
	@computed()
	@dependencies(["Composition"])
	public get CompostionProductList():string{
		if (this.Composition.length == 0) {
			return "";
		}
		return this.Composition.map((item: ProductCompositionRow) => item.ProductSku).join(", ");
	}

	@field("boolean")
	@computed()
	@print(printBoolean)
	@dependencies(["Composition"])
	public get ProductHasComposition():boolean{
		return this.Composition.length > 0;
	}


	@field("number")
	@computed()
	@rightAlign()
	@decimals(2)
	public get CompositionTotalIncVAT():number{
		return this.CompositionTotalExcVAT + this.CompositionTotalVAT;
	}


	@field("number")
	@hidden()
	public StockID:number = 0;

	@field("has-one", "ProductStock")
	public Stock:ProductStock | null = null;

	@field("has-many", "ProductWriteOffRow")
	public WriteOffProducts:ProductWriteOffRow[] = [];

	@field("string")
	@nullable()
	public BarCode:string | null = null;

	@field("number")
	@nullable()
	public CoverImageID:number | null = null;

	@field("has-one", "ProductImage")
	public CoverImage:ProductImage = new ProductImage();

	@field("has-many", "ProductImage")
	@hidden()
	public Images:ProductImage[] = [];

	@field("number")
	public ExtraFieldsID:number = 0;

	@field("has-one", "ProductExtraFields")
	public ExtraFields:ProductExtraFields | null = null;

	@field("has-many", "ProductGroupProduct")
	public Groups:ProductGroupProduct[] = [];

	@field("string")
	@computed()
	@hidden()
	@dependencies(["Groups.ProductGroup.ParentGroup"])
	public get Group():string {
		for (let group of this.Groups) {
			if (!group.ProductGroup) continue;
			if (group.ProductGroup.Level == 1) {
				return group.ProductGroup.Name;
			}
			if (group.ProductGroup.ParentGroup && group.ProductGroup.ParentGroup.Level == 1) {
				return group.ProductGroup.ParentGroup.Name;
			}
		}
		return "";
	}

	@field("string")
	@computed()
	@hidden()
	@dependencies(["Groups.ProductGroup.ParentGroup"])
	public get SubGroup():string {
		for (let group of this.Groups) {
			if (!group.ProductGroup) continue;
			if (group.ProductGroup.Level > 1) {
				return group.ProductGroup.Name;
			}
		}
		return "";
	}

	@field("number")
	@print(printUser)
	@filterValueInput("Creator")
	public CreatorID:number = 0;

	@field("string")
	@computed()
	@hidden()
	public get Creator():string {
		let user = DossierService.getUser(this.CreatorID);
		return user.getUserString();
	}

	@field("number")
	@nullable()
	public UnitID:number | null = null;

	@field("has-one", "Unit")
	@nullable()
	public Unit:Unit | null = null;

	@field("string")
	@nullable()
	@canBeFilteredNummerically()
	public LedgerAccountCode:string | null = null;

	@field("has-one", "LedgerAccount")
	public LedgerAccount:null | LedgerAccount = null;

	@field("boolean")
	public DiscountsDisabled:boolean = false;

	public AWSDocuments:ProductAwsDocument[] = [];
	public PriceRules:ProductPriceRule[] = [];
	public UnprocessedFiles: File[] = [];
	public toDeleteFiles:ProductAwsDocument[] = [];

	@field("number")
	@hidden()
	public GroupID:number | null = null;

	@field("has-one", "ProductGroupModel")
	public MainGroup:ProductGroupModel | null = null;

	@field("boolean")
	@print(printBoolean)
	public MarkedToPrintLabel:boolean = false;

	@field("has-one", "ProductPriceCategorySalesDates")
	public PriceCategorySalesDates:ProductPriceCategorySalesDates = new ProductPriceCategorySalesDates();

	@field("number")
	@computed()
	@dependencies(["Stock", "MainSupplier"])
	public get ToOrder():number {
		if (!this.Stock) return 0.0;
		if (!this.Stock.MinStock1Enabled) return 0.0;
		let settings = DossierConfigService.getReadonlyStockLocationConfigs();
		let amount = this.Stock.MinStockQty1;
		for (let i = 1; i <= StockLocationConfig.MAX_ID; i++) {
			let stockSettings = settings.getLocationSettings(i);
			if (stockSettings.AddToAutomaticOrderToSupplier == AddToAutomaticOrderToSupplier.ADD) {
				amount += this.Stock.getStockAtLocation(i);
			}else if (stockSettings.AddToAutomaticOrderToSupplier == AddToAutomaticOrderToSupplier.SUBTRACT) {
				amount -= this.Stock.getStockAtLocation(i);
			}
		}
		if (this.MainSupplier && amount > 0) {
			amount = Math.max(this.MainSupplier.MinOrderQty, amount);
		}
		if (amount < 0){
			amount = 0.0;
		}
		return amount;
	}

	@field("number")
	public DefaultValueQty2:number = 1;

	@field("boolean")
	@print(printBoolean)
	@onClick(getOpenAwsDocumentsFunc(async function(value:any):Promise<AwsDocument[]>{
		let result = await ProductService.getProduct(value.ID);
		return result.AWSDocuments;
	}))
	public HasAttachedDocument:boolean = false;

	@field("number")
	public IntrastatDataID:number = 0;

	@field("has-one", "ProductIntrastatData")
	public IntrastatData:ProductIntrastatData | null = null;

	@field("has-one", "ProductLabelSettings")
	public LabelSettings:ProductLabelSettings | null = null;

	@field("boolean")
	public SerialNumberRequired:boolean = false;


	public toUploadImages:{file:File, setAsCover:boolean, url:string}[] = [];
	public toRemoveImages:ProductImage[] = [];
	public removeIntrastatData:boolean = true;


	// eslint-disable-next-line max-lines-per-function
	public constructor(data: any = null) {
		if (data) {
			this.ID = data.ID;
			this.Active = data.Active;
			this.Name = data.Name;
			this.Sku = data.Sku;
			this.VatID = data.VatID;
			this.TimeCreated = new Date(data.TimeCreated);
			this.TimeChanged = new Date(data.TimeChanged);
			if (data.Suppliers) {
				this.Suppliers = data.Suppliers.map((s: any) => new ProductSupplier(s));
				this.MainSupplier = this.Suppliers.find(s=>s.IsMainSupplier == true) || null;
			}
			if (!this.MainSupplier && data.MainSupplier){
				this.MainSupplier = new ProductSupplier(data.MainSupplier);
			}
			this.MainSupplierID = data.MainSupplierID || 0;
			this.RecupelTax = data.RecupelTax;
			this.AuvibelTax = data.AuvibelTax;
			this.BebatTax = data.BebatTax;
			this.ReprobelTax = data.ReprobelTax;
			this.LeeggoedTax = data.LeeggoedTax;
			this.AccijnzenTax = data.AccijnzenTax;
			this.EcoboniTax = data.EcoboniTax;
			this.FostplusTax = data.FostplusTax;
			this.ExtraFieldsID = data.ExtraFieldsID;
			this.TranslatedName = new CustomTranslation(data.TranslatedName);
			if (data.Vat) {
				this.Vat = new Vat(data.Vat);
			}
			this.BuyCatalogPrice = data.BuyCatalogPrice;
			this.BuyNettoPrice = data.BuyNettoPrice;
			this.ManufacturerCode = data.ManufacturerCode;
			this.SupplierCode = data.SupplierCode;
			for (let i = 1; i <= ProductConfig.sellPriceCount; i++) {
				(this as any)["SellPrice" + i] = data["SellPrice" + i];
			}
			if (data.Composition) {
				let unsorted = (data.Composition as any[]).map((c: any) => new ProductCompositionRow(c));
				this.Composition = unsorted.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
			}
			this.BuyPriceComesFromComposition = data.BuyPriceComesFromComposition;
			this.StockID = data.StockID;
			this.BarCode = data.BarCode;
			if (data.Stock) {
				this.Stock = new ProductStock(data.Stock);
			}
			this.ComputeStock = data.ComputeStock;
			if (data.WriteOffProducts) {
				this.WriteOffProducts = data.WriteOffProducts.map((p: any) => new ProductWriteOffRow(p));
			}
			this.CoverImageID = data.CoverImageID || null;
			if (data.CoverImage) {
				this.CoverImage = new ProductImage(data.CoverImage);
			}
			if (data.Images) {
				this.Images = data.Images.map((i:any) => {
					let image = new ProductImage(i);
					image.setAsCover = image.ID == this.CoverImageID;
					return image;
				});
			}
			if (data.ExtraFields) {
				this.ExtraFields = new ProductExtraFields(data.ExtraFields);
			}

			if (data.PriceRules) {
				this.PriceRules = (data.PriceRules as any[]).map((r:any)=>new ProductPriceRule(r));
			}

			this.SellPrice1WithVat = data.SellPrice1WithVat;
			this.SellPrice2WithVat = data.SellPrice2WithVat;
			this.SellPrice3WithVat = data.SellPrice3WithVat;
			this.SellPrice4WithVat = data.SellPrice4WithVat;
			this.SellPrice5WithVat = data.SellPrice5WithVat;
			this.SellPrice6WithVat = data.SellPrice6WithVat;
			this.SellPrice7WithVat = data.SellPrice7WithVat;
			this.SellPrice8WithVat = data.SellPrice8WithVat;
			this.SellPrice9WithVat = data.SellPrice9WithVat;
			this.SellPrice10WithVat = data.SellPrice10WithVat;
			this.GroupID = data.GroupID;
			this.Groups = (data.Groups || []).map((g:any)=>new ProductGroupProduct(g));
			this.CreatorID = data.CreatorID;

			this.UnitID = data.UnitID;
			if (data.Unit && data.UnitID != 0){
				this.Unit = new Unit(data.Unit);
			}

			this.LedgerAccountCode = data.LedgerAccountCode;
			if (data.LedgerAccount && data.LedgerAccountCode) {
				this.LedgerAccount = new LedgerAccount(data.LedgerAccount);
			}

			if (data.AWSDocuments) {
				this.AWSDocuments = (data.AWSDocuments as any[]).map(d=>new ProductAwsDocument(d));
			}

			if (data.MainGroup) {
				this.MainGroup = new ProductGroupModel(data.MainGroup);
			}

			this.DiscountsDisabled = data.DiscountsDisabled || false;
			this.MarkedToPrintLabel = data.MarkedToPrintLabel || false;

			if (data.PriceCategorySalesDates){
				this.PriceCategorySalesDates = new ProductPriceCategorySalesDates(data.PriceCategorySalesDates);
			}
			this.DefaultValueQty2 = data.DefaultValueQty2;
			this.HasAttachedDocument = data.HasAttachedDocument;
			this.IntrastatDataID = data.IntrastatDataID;
			if (data.IntrastatData && data.IntrastatData.ID) {
				this.removeIntrastatData = false;
				this.IntrastatData = new ProductIntrastatData(data.IntrastatData);
			}
			this.NameStripped = data.NameStripped;
			if (data.LabelSettings) {
				this.LabelSettings = new ProductLabelSettings(data.LabelSettings);
			}
			this.SerialNumberRequired = data.SerialNumberRequired;
		} else {
			this.ExtraFields = new ProductExtraFields();
			if (UserConfigService.getSaleSettings().DefaultNewProductVatID != 0){
				this.Vat = ProductService.getDefaultNewProductVat();
				this.VatID = this.Vat.ID;
			}
			this.LedgerAccountCode = DossierConfigService.getGeneralConfig().DefaultLedgerAccountCode;
		}
	}

	public getName(lang:string):string{
		return this.TranslatedName.getTranslation(lang) || this.Name;
	}

	// eslint-disable-next-line max-lines-per-function
	public getJSON(): any {
		let BuyNettoPrice = 0;
		if (this.BuyPriceComesFromComposition) {
			BuyNettoPrice = this.getBuyPriceFromComposition();
		}
		let intrastatData:any = null;
		if (this.IntrastatData && !this.removeIntrastatData) {
			intrastatData = this.IntrastatData.getJSON();
		}
		return {
			ID: this.ID,
			Active: this.Active,
			Name: this.Name,
			Sku: this.Sku ? this.Sku.toUpperCase() : this.Sku,
			VatID: this.VatID,
			TimeCreated: this.TimeCreated.toJSON(),
			TimeChanged: this.TimeChanged.toJSON(),
			Suppliers: this.Suppliers.map((s) => s.getJSON()),
			RecupelTax: this.RecupelTax,
			AuvibelTax: this.AuvibelTax,
			BebatTax: this.BebatTax,
			ReprobelTax: this.ReprobelTax,
			LeeggoedTax: this.LeeggoedTax,
			AccijnzenTax: this.AccijnzenTax,
			EcoboniTax: this.EcoboniTax,
			FostplusTax: this.FostplusTax,
			CoverImageID: this.CoverImageID || null,
			SellPrice1: this.SellPrice1,
			SellPrice2: this.SellPrice2,
			SellPrice3: this.SellPrice3,
			SellPrice4: this.SellPrice4,
			SellPrice5: this.SellPrice5,
			SellPrice6: this.SellPrice6,
			SellPrice7: this.SellPrice7,
			SellPrice8: this.SellPrice8,
			SellPrice9: this.SellPrice9,
			SellPrice10: this.SellPrice10,
			SellPrice1WithVat: this.SellPrice1WithVat,
			SellPrice2WithVat: this.SellPrice2WithVat,
			SellPrice3WithVat: this.SellPrice3WithVat,
			SellPrice4WithVat: this.SellPrice4WithVat,
			SellPrice5WithVat: this.SellPrice5WithVat,
			SellPrice6WithVat: this.SellPrice6WithVat,
			SellPrice7WithVat: this.SellPrice7WithVat,
			SellPrice8WithVat: this.SellPrice8WithVat,
			SellPrice9WithVat: this.SellPrice9WithVat,
			SellPrice10WithVat: this.SellPrice10WithVat,
			TranslatedName: this.TranslatedName.getJSON(),
			ComputeStock: this.ComputeStock,
			Composition: this.Composition.map((c) => c.getJSON()),
			BuyPriceComesFromComposition: this.BuyPriceComesFromComposition,
			BuyNettoPrice,
			WriteOffProducts: this.WriteOffProducts.map((c) => c.getJSON()),
			StockID: this.StockID,
			BarCode: this.BarCode,
			ExtraFieldsID: this.ExtraFieldsID,
			ExtraFields: this.ExtraFields ? this.ExtraFields.getJSON() : null,
			Groups: this.Groups.map(g=>g.getJSON()),
			CreatorID: this.CreatorID,
			Stock: this.Stock ? this.Stock.getJSON() : null,
			UnitID: this.UnitID,
			LedgerAccountCode: this.LedgerAccountCode || null,
			DiscountsDisabled: this.DiscountsDisabled,
			GroupID: this.GroupID,
			MarkedToPrintLabel: this.MarkedToPrintLabel,
			MainSupplierID: this.MainSupplierID,
			PriceCategorySalesDates: this.PriceCategorySalesDates ? this.PriceCategorySalesDates.getJSON() : null,
			DefaultValueQty2: this.DefaultValueQty2,
			HasAttachedDocument: this.HasAttachedDocument,
			IntrastatData: intrastatData,
			LabelSettings: this.LabelSettings ? this.LabelSettings.getJSON() : null,
			PriceRules: (this.PriceRules || []).map(r=>r.getJSON()).sort((a,b):number=>{
				return a.DisplayOrder - b.DisplayOrder;
			}),
			SerialNumberRequired: this.SerialNumberRequired,
		};
	}

	public getMainSupplier(): ProductSupplier | null {
		if (!this.MainSupplier) {
			for (let supplier of this.Suppliers) {
				if (supplier.IsMainSupplier) {
					return supplier;
				}
			}
		}
		return this.MainSupplier;
	}

	public getProductTotals(): ProductTotal {
		let mainSupplier = this.getMainSupplier();
		let TotalBuy = 0;
		if (mainSupplier) {
			TotalBuy = mainSupplier.NettoPrice;
		}
		return {
			rows: this.Composition,
			TotalExcVAT: this.CompositionTotalExcVAT,
			TotalIncVAT: this.CompositionTotalIncVAT,
			TotalVAT: this.CompositionTotalVAT,
			TotalLeeggoed: this.LeeggoedTax,
			CurrencyRate: 1,
			TotalBuy
		};
	}

	public getTaxRows(qty: number, vat: number,countryCode: string): SaleRow[] {
		let rows = [] as SaleRow[];

		function isTax(tax: number): boolean {
			if (tax == 0) return false;
			return true;
		}

		function createTax(description: string, amount: number | undefined) {
			if (!amount) return;
			if (!isTax(amount)) return;
			let row = new SaleRow();
			row.Vat = vat || 21;
			let UnitPrice = amount / ((row.Vat/100)+1);
			row.ProductDescription = description;
			row.UnitPrice = UnitPrice;
			row.Discount = 0;
			row.setAmount(qty);
			rows.push(row);
		}

		createTax(i18n.t("common.taxes.recupel").toString(), this.RecupelTax);
		createTax(i18n.t("common.taxes.auvibel").toString(), this.AuvibelTax);
		if(countryCode=="BE"){
			createTax(i18n.t("common.taxes.bebat").toString(), this.BebatTax);
		}
		createTax(i18n.t("common.taxes.reprobel").toString(), this.ReprobelTax);
		return rows;
	}

	public setPriceByCategory(category:number, price:number){
		(this[`SellPrice${category}` as keyof Product] as number) = price;
		let vat = VatService.getVat(this.VatID);
		let vatValue = vat.Value;
		(this[`SellPrice${category}WithVat` as keyof Product] as number) = price * (1.0 + (vatValue / 100.0));
	}

	public getBuyPriceFromComposition():number{
		let total = 0.0;
		for (let item of this.Composition) {
			if (item.ReferencedProduct && item.ReferencedProduct.MainSupplier){
				total+=item.Amount * item.ReferencedProduct.MainSupplier.NettoPrice;
			}
		}
		return total;
	}

	private getSellPriceWithVatAndTax(sellPriceId:number):number{
		return ((this as any)[`SellPrice${sellPriceId}WithVat`] + ((this.AccijnzenTax + this.EcoboniTax + this.RecupelTax) * (1 + ((this.Vat?.Value || 1) / 100.0))));
	}
}
