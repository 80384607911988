import { View } from "@/models/view";

let converterList:{[key:string]:string} = {
	"Active": "active",
	"ComputedFriendlyID": "nr",
	"Info": "info",
	"Vat": "vat",
	"PaymentDays": "payment-days",
	"CommercialVat": "commercial-vat",
	"MaxReminder": "max-reminder",
	"CompanyName": "company-name",
	"MainAddress.LastName": "lastname",
	"MainAddress.FirstName": "firstname",
	"MainAddress.AddressLine1": "address-line-1",
	"MainAddress.AddressLine2": "address-line-2",
	"MainAddress.Number": "number",
	"MainAddress.Box": "box",
	"MainAddress.Postal": "postal",
	"MainAddress.Email": "email",
	"MainAddress.Phone": "phone",
	"MainAddress.Mobile": "mobile",
	"MainAddress.Fax": "fax",
	"MainAddress.City": "city",
	"MainAddress.Lng": "lng",
	"MainAddress.CountryCode": "country-code",
	"PaymentEndMonth": "payment-end-month",
	"ExtraFields.Num1": "ev-num-1",
	"ExtraFields.Num2": "ev-num-2",
	"ExtraFields.Num3": "ev-num-3",
	"ExtraFields.Num4": "ev-num-4",
	"ExtraFields.Num5": "ev-num-5",
	"ExtraFields.Num6": "ev-num-6",
	"ExtraFields.Num7": "ev-num-7",
	"ExtraFields.Num8": "ev-num-8",
	"ExtraFields.Num9": "ev-num-9",
	"ExtraFields.Num10": "ev-num-10",
	"ExtraFields.Num11": "ev-num-11",
	"ExtraFields.Num12": "ev-num-12",
	"ExtraFields.Num13": "ev-num-13",
	"ExtraFields.Num14": "ev-num-14",
	"ExtraFields.Num15": "ev-num-15",
	"ExtraFields.Num16": "ev-num-16",
	"ExtraFields.Num17": "ev-num-17",
	"ExtraFields.Num18": "ev-num-18",
	"ExtraFields.Num19": "ev-num-19",
	"ExtraFields.Num20": "ev-num-20",
	"ExtraFields.Text1": "ev-text-1",
	"ExtraFields.Text2": "ev-text-2",
	"ExtraFields.Text3": "ev-text-3",
	"ExtraFields.Text4": "ev-text-4",
	"ExtraFields.Text5": "ev-text-5",
	"ExtraFields.Text6": "ev-text-6",
	"ExtraFields.Text7": "ev-text-7",
	"ExtraFields.Text8": "ev-text-8",
	"ExtraFields.Text9": "ev-text-9",
	"ExtraFields.Text10": "ev-text-10",
	"ExtraFields.Text11": "ev-text-11",
	"ExtraFields.Text12": "ev-text-12",
	"ExtraFields.Text13": "ev-text-13",
	"ExtraFields.Text14": "ev-text-14",
	"ExtraFields.Text15": "ev-text-15",
	"ExtraFields.Text16": "ev-text-16",
	"ExtraFields.Text17": "ev-text-17",
	"ExtraFields.Text18": "ev-text-18",
	"ExtraFields.Text19": "ev-text-19",
	"ExtraFields.Text20": "ev-text-20",
	"ExtraFields.Bool1": "ev-bool-1",
	"ExtraFields.Bool2": "ev-bool-2",
	"ExtraFields.Bool3": "ev-bool-3",
	"ExtraFields.Bool4": "ev-bool-4",
	"ExtraFields.Bool5": "ev-bool-5",
	"ExtraFields.Bool6": "ev-bool-6",
	"ExtraFields.Bool7": "ev-bool-7",
	"ExtraFields.Bool8": "ev-bool-8",
	"ExtraFields.Bool9": "ev-bool-9",
	"ExtraFields.Bool10": "ev-bool-10",
	"ExtraFields.Bool11": "ev-bool-11",
	"ExtraFields.Bool12": "ev-bool-12",
	"ExtraFields.Bool13": "ev-bool-13",
	"ExtraFields.Bool14": "ev-bool-14",
	"ExtraFields.Bool15": "ev-bool-15",
	"ExtraFields.Bool16": "ev-bool-16",
	"ExtraFields.Bool17": "ev-bool-17",
	"ExtraFields.Bool18": "ev-bool-18",
	"ExtraFields.Bool19": "ev-bool-19",
	"ExtraFields.Bool20": "ev-bool-20",
};

export function replaceContactColsWithCsvCols(view:View, colIndex:number):string {
	if (!view.Columns[colIndex]) return "";
	let col = view.Columns[colIndex];
	return converterList[col.Property] || "";
}
