
import Vue from "vue";
import AppHumanFilterEditValueCustomJournal from "./HumanFilterEditValueCustomJournal.vue";
import AppHumanFilterEditValueCustomSaleType from "./HumanFilterEditValueCustomSaleType.vue";
import AppHumanFilterEditValueCustomBookYear from "./HumanFilterEditValueCustomBookYear.vue";
import AppHumanFilterEditValueCustomUser from "./HumanFilterEditValueCustomUser.vue";
import AppHumanFilterEditValueCustomContact from "./HumanFilterEditValueCustomContact.vue";
import AppHumanFilterEditValueCustomContactSupplier from "./HumanFilterEditValueCustomContactSupplier.vue";
import AppHumanFilterEditValueCustomProduct from "./HumanFilterEditValueCustomProduct.vue";
import AppHumanFilterEditValueCustomCountryCode from "./HumanFilterEditValueCustomCountryCode.vue";
import AppHumanFilterEditValueCustomSellPriceCategory from "./HumanFilterEditValueCustomSellPriceCategory.vue";
import AppHumanFilterEditValueCustomBankKind from "./HumanFilterEditValueCustomBankKind.vue";
import AppHumanFilterEditValueCustomCar from "./HumanFilterEditValueCustomCar.vue";
import AppHumanFilterEditValueCustomVatRegime from "./HumanFilterEditValueCustomVatRegime.vue";
import AppHumanFilterEditValueCustomVatRegimePurchase from "./HumanFilterEditValueCustomVatRegimePurchase.vue";
import AppHumanFilterEditValueCustomCrmStatus from "./HumanFilterEditValueCustomCrmStatus.vue";
import AppHumanFilterEditValueCustomTaskStatus from "./HumanFilterEditValueCustomTaskStatus.vue";
import AppHumanFilterEditValueCustomSale from "./HumanFilterEditValueCustomSale.vue";
import { HumanFilter } from "@/utils/human-filter";

export default Vue.extend({
	props: {
		inputType: String,
		value: {
			type: [Array as ()=>string[], String]
		},
		valueIndex: {
			type: Number
		},
		label: {
			type: String
		},
		multi: Boolean,
		filter: {
			type: Object as ()=>HumanFilter
		}
	},
	methods: {
		onInput(event:string | string[]){
			this.$emit("input", event);
		}
	},
	components: {
		AppHumanFilterEditValueCustomJournal,
		AppHumanFilterEditValueCustomSaleType,
		AppHumanFilterEditValueCustomBookYear,
		AppHumanFilterEditValueCustomUser,
		AppHumanFilterEditValueCustomContact,
		AppHumanFilterEditValueCustomContactSupplier,
		AppHumanFilterEditValueCustomProduct,
		AppHumanFilterEditValueCustomCountryCode,
		AppHumanFilterEditValueCustomSellPriceCategory,
		AppHumanFilterEditValueCustomBankKind,
		AppHumanFilterEditValueCustomCar,
		AppHumanFilterEditValueCustomVatRegime,
		AppHumanFilterEditValueCustomVatRegimePurchase,
		AppHumanFilterEditValueCustomCrmStatus,
		AppHumanFilterEditValueCustomTaskStatus,
		AppHumanFilterEditValueCustomSale
	}
});
