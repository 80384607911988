import { ServerConfig } from "@/config/config";
import { DossierConfig } from "@/models/dossier-config";
import { DecimalConfig } from "@/models/dossier-configs/decimal-config";
import { DefaultReportConfig } from "@/models/dossier-configs/default-report-config";
import { ExtraFieldNamesConfigs } from "@/models/dossier-configs/extra-field-names-configs";
import { FriendlyNumberConfigs } from "@/models/dossier-configs/friendly-number-configs";
import { GeneralDossierConfig } from "@/models/dossier-configs/general-config";
import { JournalsConfig } from "@/models/dossier-configs/journals-config";
import { LanguagesConfig } from "@/models/dossier-configs/languages";
import { PluginConfig } from "@/models/dossier-configs/plugin";
import { ReminderConfig } from "@/models/dossier-configs/reminder-config";
import { SaleConfig } from "@/models/dossier-configs/sale-config";
import { SaleConfigs } from "@/models/dossier-configs/sale-configs";
import { SellCategories } from "@/models/dossier-configs/sell-categories";
import { StockLocationConfig } from "@/models/dossier-configs/stock-location";
import { SaleType } from "@/models/base";
import { AuthService } from "@/services/auth-service";
import { Axios } from "@/utils/axios";
import { IGetJSON } from "@/utils/common";
import { AxiosResponse } from "axios";
import { MainSocketService } from "./main-socket-service";
import { MollieConfig } from "@/models/dossier-configs/mollie-config";
import { ContactsConfig } from "@/models/dossier-configs/contacts";
import { ClientPortalConfig } from "@/models/dossier-configs/client-portal";
import { DataSourcesConfig as DataSourcesConfig } from "@/models/dossier-configs/data-sources";
import { KrampConfig } from "@/models/dossier-configs/kramp-config";
import { StockScannerConfig } from "@/models/dossier-configs/stock-scanner";
import { AutoBarcode } from "@/models/dossier-configs/auto-barcode";
import {TaxesConfig} from "@/models/dossier-configs/taxes-config";
import {CashierButtonConfig, CashierConfig} from "@/models/dossier-configs/cashier";
import { IInitAppResponse } from "./init-service";
import { BookkeepingConfig} from "@/models/dossier-configs/bookkeeping";
import { CarpassConfig } from "@/models/dossier-configs/carpass";
import { SendcloudConfig } from "@/models/dossier-configs/sendcloud";
import { PurchaseConfig } from "@/models/dossier-configs/purchase-config";
import { BrowseService } from "./browse-service";

const url = ServerConfig.host + "/dossier-config";
const socketUrl = "/dossier-config/";

enum DossierConfigKey {
	GENERAL = "general",
	SELL_CATEGORIES = "sell-categories",
	LANGUAGES = "languages",
	FRIENDLY_IDS = "friendly-ids",
	REMINDER_CONF = "reminder-conf",
	REPORT = "report",
	PLUGINS = "plugins",
	DECIMALS = "decimals",
	SALE_CONFIG = "sale-conf",
	STOCK_LOCATIONS = "stock-locations",
	STOCK_SCANNER = "stock-scanner",
	JOURNALS = "journals",
	EXTRA_FIELD_NAMES = "extra-field-names",
	MOLLIE = "mollie",
	TAXES = "taxes",
	CONTACTS = "contacts",
	CLIENT_PORTAL = "client-portal",
	BROWSE_EXTRA_FIELDS = "browse-extra-fields",
	DATA_SOURCES = "data-sources",
	KRAMP = "kramp",
	AUTO_BARCODE="auto-barcode",
	BOOKKEEPING="bookkeeping",

	CASHIER="cashier",
	CASHIER_BUTTONS="cashier_buttons",
	CARPASS="carpass",
	SENDCLOUD="sendcloud",

	PURCHASES="purchases"
}

export interface IMollieProfile {
	ID:string;
	Name:string;
}


class DossierConfigServiceClas{
	private configs:{ [key: string]: DossierConfig } = {};

	private readonlyExtraFieldNames:ExtraFieldNamesConfigs = new ExtraFieldNamesConfigs();
	private readonlyStockLocationsConfig:StockLocationConfig = new StockLocationConfig()

	public constructor(){
		requestAnimationFrame(() => {
			AuthService.on("logout", () => {
				this.configs = {};
			});
			MainSocketService.on(socketUrl + "update", (key:string, data: any) => {
				let config = new DossierConfig(key, data);
				let newConf = {} as { [key: string]: DossierConfig };
				for (let key of Object.keys(this.configs)) {
					newConf[key] = this.configs[key];
				}
				newConf[config.SettingsKey] = config;
				this.configs = newConf;
				if (config.SettingsKey == DossierConfigKey.STOCK_LOCATIONS) {
					this.readonlyStockLocationsConfig = new StockLocationConfig(this.configs[key].Value);
				}else if (config.SettingsKey == DossierConfigKey.EXTRA_FIELD_NAMES){
					this.readonlyExtraFieldNames = new ExtraFieldNamesConfigs(this.configs[key].Value);
					BrowseService.browses.forEach(b=>b.setExtraFieldNameOverride());
				}
			});
		});
	}

	getConfigValue(key: string): any {
		let val = this.configs[key];
		if (!val) {
			throw new Error("Config not found: " + key);
		}
		return val.Value;
	}

	getGeneralConfig(): GeneralDossierConfig {
		return new GeneralDossierConfig(this.getConfigValue(DossierConfigKey.GENERAL));
	}

	getSellCategories(): SellCategories {
		return new SellCategories(this.getConfigValue(DossierConfigKey.SELL_CATEGORIES));
	}

	getLanguages(): LanguagesConfig {
		return new LanguagesConfig(this.getConfigValue(DossierConfigKey.LANGUAGES));
	}

	getFriendlyNumbers(): FriendlyNumberConfigs {
		return new FriendlyNumberConfigs(this.getConfigValue(DossierConfigKey.FRIENDLY_IDS));
	}

	getReminderConfig(): ReminderConfig {
		return new ReminderConfig(this.getConfigValue(DossierConfigKey.REMINDER_CONF));
	}

	getDefaultReportConfig(): DefaultReportConfig {
		return new DefaultReportConfig(this.getConfigValue(DossierConfigKey.REPORT));
	}

	getPluginConfig(): PluginConfig {
		return new PluginConfig(this.getConfigValue(DossierConfigKey.PLUGINS));
	}

	getDecimalSettings(): DecimalConfig {
		return new DecimalConfig(this.getConfigValue(DossierConfigKey.DECIMALS));
	}

	getSaleConfigs(): SaleConfigs {
		return new SaleConfigs(this.getConfigValue(DossierConfigKey.SALE_CONFIG));
	}

	getSaleConfig(saleType: number | SaleType): SaleConfig {
		if (typeof saleType == "object") {
			saleType = saleType.ID;
		}
		return this.getSaleConfigs().getConfig(saleType);
	}

	getStockLocationConfig(): StockLocationConfig {
		return new StockLocationConfig(this.getConfigValue(DossierConfigKey.STOCK_LOCATIONS));
	}

	// Use only in extra field models to improve performance in browses
	getReadonlyStockLocationConfigs():StockLocationConfig{
		return this.readonlyStockLocationsConfig;
	}

	getStockScannerConfig(): StockScannerConfig {
		return new StockScannerConfig(this.getConfigValue(DossierConfigKey.STOCK_SCANNER));
	}

	getJournalsConfig(): JournalsConfig {
		return new JournalsConfig(this.getConfigValue(DossierConfigKey.JOURNALS));
	}

	getExtraFieldNamesConfigs(): ExtraFieldNamesConfigs {
		return new ExtraFieldNamesConfigs(this.getConfigValue(DossierConfigKey.EXTRA_FIELD_NAMES));
	}

	// Use only in extra field models to improve performance in browses
	getReadonlyExtraFieldNamesConfigs():ExtraFieldNamesConfigs{
		return this.readonlyExtraFieldNames;
	}

	getMollieConfig():MollieConfig {
		return new MollieConfig(this.getConfigValue(DossierConfigKey.MOLLIE));
	}

	getTaxesConfig():TaxesConfig {
		return new TaxesConfig(this.getConfigValue(DossierConfigKey.TAXES));
	}

	getContactsConfig():ContactsConfig{
		return new ContactsConfig(this.getConfigValue(DossierConfigKey.CONTACTS));
	}

	getPortalConfig():ClientPortalConfig{
		let config = this.getConfigValue(DossierConfigKey.CLIENT_PORTAL);
		return new ClientPortalConfig(config);
	}

	getCashierConfig():CashierConfig{
		let config = this.getConfigValue(DossierConfigKey.CASHIER);
		return new CashierConfig(config);
	}

	getCashierButtonConfig():CashierButtonConfig{
		let config = this.getConfigValue(DossierConfigKey.CASHIER_BUTTONS);
		return new CashierButtonConfig(config);
	}

	getBookkeepingConfig():BookkeepingConfig{
		let config = this.getConfigValue(DossierConfigKey.BOOKKEEPING);
		return new BookkeepingConfig(config);
	}

	getDataSourcesConfig():DataSourcesConfig {
		return new DataSourcesConfig(this.getConfigValue(DossierConfigKey.DATA_SOURCES));
	}

	getKrampConfig():KrampConfig{
		return new KrampConfig(this.getConfigValue(DossierConfigKey.KRAMP));
	}

	getAutoBarcodeConfig():AutoBarcode{
		return new AutoBarcode(this.getConfigValue(DossierConfigKey.AUTO_BARCODE));
	}

	getCarpassConfig():CarpassConfig {
		return new CarpassConfig(this.getConfigValue(DossierConfigKey.CARPASS));
	}

	getSendcloudConfig():SendcloudConfig{
		return new SendcloudConfig(this.getConfigValue(DossierConfigKey.SENDCLOUD));
	}

	getPurchaseConfig():PurchaseConfig{
		return new PurchaseConfig(this.getConfigValue(DossierConfigKey.PURCHASES));
	}

	async setPortalConfig(config: ClientPortalConfig): Promise<ClientPortalConfig> {
		return this.putAndParseConfig<ClientPortalConfig>(config, DossierConfigKey.CLIENT_PORTAL, ClientPortalConfig);
	}

	async setCashierConfig(config: CashierConfig): Promise<CashierConfig> {
		return this.putAndParseConfig<CashierConfig>(config, DossierConfigKey.CASHIER, CashierConfig);
	}

	async setCashierButtonsConfig(config: CashierButtonConfig): Promise<CashierButtonConfig> {
		return this.putAndParseConfig<CashierButtonConfig>(config, DossierConfigKey.CASHIER_BUTTONS, CashierButtonConfig);
	}

	async setBookkeepingConfig(config: BookkeepingConfig): Promise<BookkeepingConfig> {
		return this.putAndParseConfig<BookkeepingConfig>(config, DossierConfigKey.BOOKKEEPING, BookkeepingConfig);
	}

	async putAndParseConfig<T extends IGetJSON>(config: T, key: string, construct: { new(data:any): T }): Promise<T> {
		let result = await Axios.put(`${url}/${key}`, config);
		return new construct(result.data);
	}

	async getByKey(key: string): Promise<AxiosResponse> {
		return Axios.get(`${url}/${key}`);
	}

	async saveDecimalSettings(settings: DecimalConfig): Promise<DecimalConfig> {
		return this.putAndParseConfig<DecimalConfig>(settings, DossierConfigKey.DECIMALS, DecimalConfig);
	}

	async saveFriendlyNumberConfigs(config: FriendlyNumberConfigs) {
		await this.putAndParseConfig<FriendlyNumberConfigs>(config, DossierConfigKey.FRIENDLY_IDS, FriendlyNumberConfigs);
	}

	async saveSellCategoryNames(cats: SellCategories): Promise<SellCategories> {
		return this.putAndParseConfig<SellCategories>(cats, DossierConfigKey.SELL_CATEGORIES, SellCategories);
	}

	async saveLanguagesConfig(config: LanguagesConfig): Promise<LanguagesConfig> {
		return this.putAndParseConfig<LanguagesConfig>(config, DossierConfigKey.LANGUAGES, LanguagesConfig);
	}

	async saveGeneralConfig(config: GeneralDossierConfig): Promise<GeneralDossierConfig> {
		return this.putAndParseConfig<GeneralDossierConfig>(config, DossierConfigKey.GENERAL, GeneralDossierConfig);
	}

	async saveDefaultReportConfig(config: DefaultReportConfig): Promise<DefaultReportConfig> {
		return this.putAndParseConfig<DefaultReportConfig>(config, DossierConfigKey.REPORT, DefaultReportConfig);
	}

	async savePluginConfig(config: PluginConfig): Promise<PluginConfig> {
		return this.putAndParseConfig<PluginConfig>(config, DossierConfigKey.PLUGINS, PluginConfig);
	}

	async setSaleConfigs(conf: SaleConfigs): Promise<SaleConfigs> {
		return this.putAndParseConfig<SaleConfigs>(conf, DossierConfigKey.SALE_CONFIG, SaleConfigs);
	}

	async setSaleTypeConfig(saleType: number | SaleType, config: SaleConfig): Promise<SaleConfigs> {
		if (typeof (saleType) == "object") {
			var id = saleType.ID;
		} else {
			var id = saleType;
		}
		let conf = this.getSaleConfigs();
		conf.setConfig(id, config);

		return this.putAndParseConfig<SaleConfigs>(conf, DossierConfigKey.SALE_CONFIG, SaleConfigs);
	}

	async setReminderConfig(config: ReminderConfig): Promise<ReminderConfig> {
		return this.putAndParseConfig<ReminderConfig>(config, DossierConfigKey.REMINDER_CONF, ReminderConfig);
	}

	async setJournalsConfig(config: JournalsConfig): Promise<JournalsConfig> {
		return this.putAndParseConfig<JournalsConfig>(config, DossierConfigKey.JOURNALS, JournalsConfig);
	}

	async setDataSourcesConfig(config:DataSourcesConfig): Promise<DataSourcesConfig> {
		return this.putAndParseConfig<DataSourcesConfig>(config, DossierConfigKey.DATA_SOURCES, DataSourcesConfig);
	}

	async setPurchaseConfig(config:PurchaseConfig):Promise<PurchaseConfig>{
		return this.putAndParseConfig<PurchaseConfig>(config, DossierConfigKey.PURCHASES, PurchaseConfig);
	}


	async getAllConfigs(): Promise<void> {
		let response = await Axios.get(url, {params: {limit: 100}});
		let result:{[key:string]:DossierConfig} = {};
		for (let conf of response.data){
			let val = new DossierConfig(conf.SettingsKey, conf.Value);
			result[val.SettingsKey] = val;
		}
		this.configs = result;
		this.readonlyExtraFieldNames = this.getExtraFieldNamesConfigs();
	}

	async saveStockLocationConfig(config: StockLocationConfig): Promise<StockLocationConfig> {
		let res = this.putAndParseConfig<StockLocationConfig>(config, DossierConfigKey.STOCK_LOCATIONS, StockLocationConfig);
		this.readonlyStockLocationsConfig = new StockLocationConfig(res);
		return res;
	}

	async saveStockScannerConfig(config: StockScannerConfig): Promise<StockScannerConfig> {
		return this.putAndParseConfig<StockScannerConfig>(config, DossierConfigKey.STOCK_SCANNER, StockScannerConfig);
	}

	async saveExtraFieldNamesConfigs(config: ExtraFieldNamesConfigs): Promise<ExtraFieldNamesConfigs> {
		let res = await this.putAndParseConfig<ExtraFieldNamesConfigs>(config, DossierConfigKey.EXTRA_FIELD_NAMES, ExtraFieldNamesConfigs);
		this.readonlyExtraFieldNames = new ExtraFieldNamesConfigs(res.getJSON());
		return res;
	}

	async saveMollieConfig(config:MollieConfig):Promise<MollieConfig> {
		return this.putAndParseConfig<MollieConfig>(config, DossierConfigKey.MOLLIE, MollieConfig);
	}

	async saveTaxesConfig(config:TaxesConfig):Promise<TaxesConfig> {
		return this.putAndParseConfig<TaxesConfig>(config, DossierConfigKey.TAXES, TaxesConfig);
	}

	async saveContactsConfig(config:ContactsConfig):Promise<ContactsConfig>{
		return this.putAndParseConfig<ContactsConfig>(config, DossierConfigKey.CONTACTS, ContactsConfig);
	}

	async saveKrampConfig(config:KrampConfig):Promise<KrampConfig> {
		return this.putAndParseConfig<KrampConfig>(config, DossierConfigKey.KRAMP, KrampConfig);
	}

	async setAutoBarcode(config:AutoBarcode):Promise<AutoBarcode>{
		return this.putAndParseConfig<AutoBarcode>(config, DossierConfigKey.AUTO_BARCODE, AutoBarcode);
	}

	async setCarpassConfig(config:CarpassConfig):Promise<CarpassConfig>{
		return this.putAndParseConfig<CarpassConfig>(config, DossierConfigKey.CARPASS, CarpassConfig);
	}

	async setSendcloudConfig(config:SendcloudConfig):Promise<SendcloudConfig>{
		return this.putAndParseConfig<SendcloudConfig>(config, DossierConfigKey.SENDCLOUD, SendcloudConfig);
	}

	async connectWithMollie():Promise<void>{
		let res = await Axios.get(`${url}/mollie-connect-url`);
		location.href = res.data.URL;
	}


	async getMollieProfiles():Promise<IMollieProfile[]>{
		let res = await Axios.get(`${url}/mollie-profiles`);
		return (res.data || []).map((d:any)=>{
			return {
				ID: d.ID,
				Name: d.Name
			} as IMollieProfile;
		});
	}


	async createMollieProfgile(name:string, website:string, email:string, phone:string):Promise<IMollieProfile>{
		let res = await Axios.post(`${url}/mollie-profile`, {Name: name, Website: website, Email: email, Phone: phone});
		return {
			ID: res.data.ID,
			Name: res.data.Name
		};
	}

	async finishConnectWithMollie(code:string):Promise<MollieConfig>{
		let result = await Axios.post(`${url}/finish-mollie-connect/${code}`);
		return new MollieConfig(result.data);
	}

	async mollieDisconnect():Promise<MollieConfig>{
		let result = await Axios.post(`${url}/mollie-disconnect`);
		return new MollieConfig(result.data);
	}


	init(data:IInitAppResponse){
		let result:{[key:string]:DossierConfig} = {};
		for (let conf of data.DossierConfigs){
			result[conf.SettingsKey] = conf;
		}
		this.configs = result;
		this.readonlyExtraFieldNames = this.getExtraFieldNamesConfigs();
		this.readonlyStockLocationsConfig = this.getStockLocationConfig();
	}
};

export const DossierConfigService = new DossierConfigServiceClas();