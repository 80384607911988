export class CashierSettings {
	ConnectedDisplay = "";
	DefaultStartCash = 0;
	DefaultSaleType = 9;
	DefaultStartAmount = 500;

	constructor(data?:any) {
		if (!data) return;
		this.ConnectedDisplay = data.ConnectedDisplay;
		this.DefaultStartCash = data.DefaultStartCash;
		this.DefaultSaleType = data.DefaultSaleType;
		this.DefaultStartAmount = data.DefaultStartAmount !== undefined ? data.DefaultStartAmount : 500;
	}

	getJSON() {
		return {
			ConnectedDisplay: this.ConnectedDisplay,
			DefaultStartCash: this.DefaultStartCash,
			DefaultSaleType: this.DefaultSaleType,
			DefaultStartAmount: this.DefaultStartAmount
		};
	}
}