import { ElectronFunctions } from "./electron/electron";

let electronPort:string | null = null;
function initIfElectron(){
	electronPort = new URLSearchParams(window.location.search).get("electronPort");
	if (!electronPort) {
		electronPort = sessionStorage.getItem("electron-port");
	}
	if (electronPort) {
		sessionStorage.setItem("electron-port", electronPort);
	}
	if (electronPort) {
		console.log({electronPort});
		requestAnimationFrame(()=>{
			ElectronFunctions.initializeElectron();
		});
	}
}

initIfElectron();

export const BrowserInfo = {
	isFirefox() {
		return navigator.userAgent.indexOf("Firefox") != -1;
	},
	isChrome() {
		return navigator.userAgent.indexOf("Chrome") != -1;
	},
	isIE() {
		return navigator.userAgent.indexOf("MSIE") != -1;
	},
	isEdge() {
		if (this.isChrome()) {
			return false;
		}
		if (this.isFirefox()) {
			return false;
		}
		return navigator.userAgent.indexOf("Edge") != -1;
	},
	isElectron() {
		return electronPort != null;
	},
	getElectronPort():string{
		return electronPort || "";
	},
	isSafari(){
		if (this.isChrome()) {
			return false;
		}
		return navigator.userAgent.toLowerCase().indexOf("safari") != -1;
	},
	isAndroid():boolean {
		return navigator.userAgent.indexOf("Android") != -1;
	},
	isIOS():boolean {
		return /iPhone|iPad|iPod/.test(navigator.userAgent);
	},
	isMobile():boolean {
		return this.isAndroid() || this.isIOS();
	},
	getElectronUrl():string{
		return "http://localhost:" + (BrowserInfo.getElectronPort() || 0);
	}
};
