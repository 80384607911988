export class CalendarSettings {
	OpenedCalendarIDs = [] as number[];
	OpenedGoogleCalendarIDs = [] as string[];
	OpenedTaskFolderIDs = [] as number[];
	WidgetCalendarIDs = [] as number[];
	WidgetGoogleCalendarIDs = [] as string[];
	DefaultEventDuration:number = 1;

	constructor(data:any) {
		this.OpenedCalendarIDs = data.OpenedCalendarIDs;
		this.OpenedGoogleCalendarIDs = data.OpenedGoogleCalendarIDs;
		this.OpenedTaskFolderIDs = data.OpenedTaskFolderIDs || [];
		this.WidgetCalendarIDs = data.WidgetCalendarIDs || [];
		this.WidgetGoogleCalendarIDs = data.WidgetGoogleCalendarIDs || [];
		this.DefaultEventDuration = data.DefaultEventDuration || 1;
	}

	getJSON() {
		return {
			OpenedCalendarIDs: this.OpenedCalendarIDs,
			OpenedGoogleCalendarIDs: this.OpenedGoogleCalendarIDs,
			OpenedTaskFolderIDs: this.OpenedTaskFolderIDs,
			WidgetCalendarIDs: this.WidgetCalendarIDs,
			WidgetGoogleCalendarIDs: this.WidgetGoogleCalendarIDs,
			DefaultEventDuration: this.DefaultEventDuration
		};
	}
}