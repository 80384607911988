import { v4 } from "uuid";
import { DashboardBankData } from "./dashboard-bank-data";
import { DashboardChartData } from "./dashboard-chart-data";
import { DashboardChartDataWithBanks } from "./dashboard-chart-data-with-banks";
import { DashboardIframeData } from "./dashboard-iframe-data";
import { DashboardImageData } from "./dashboard-image-data";
import { DashboardPlacement } from "./dashboard-placement";
import { DashboardRevenuePerMonthCompareData } from "./dashboard-revenue-per-month-compare-data";
import { DashboardTasksData } from "./dashboard-tasks-data";
import { DashboardYearOverviewPerMonthData } from "./dashboard-year-overview-per-month-data";

export enum DashboardItemType {
	TOTAL_PROFIT =  "total-profit",
	TOTAL_OPEN =  "total-open",
	TOTAL_REVENUE =  "total-revenue",
	BANK_SALDO = "bank-saldo",
	OPEN_CLIENTS =  "open-clients",
	REVENUE_PER_MONTH_COMPARE =  "revenue-per-month-compare",
	REVENUE_PER_MONTH_TOTAL =  "revenue-per-month-total",
	PURCHASE_PER_MONTH_COMPARE =  "purchase-per-month-compare",
	CASH_IN_FLOW =  "cash-in-flow",
	TASK_LIST =  "task-list",
	CALENDAR =  "calendar",
	REVENUE_NOW_COMPARE = "revenue-now-compare",
	YEAR_OVERVIEW_PER_MONTH = "year-overview-per-month",
	REVENUE_LAST_MONTH_COMPARE = "revenue-last-month-compare",
	IFRAME = "iframe",
	IMAGE = "image",
}

export type DashboardData = null | DashboardChartData | DashboardIframeData | DashboardImageData | DashboardRevenuePerMonthCompareData | DashboardTasksData | DashboardYearOverviewPerMonthData | DashboardBankData | DashboardChartDataWithBanks;
export const DashboardItemsVisibleWithoutPermissions:DashboardItemType[] = [DashboardItemType.IMAGE, DashboardItemType.IFRAME];
const DashboardItemTypesThatAreOfTypeChartData:DashboardItemType[] = [
	DashboardItemType.TOTAL_OPEN,
	DashboardItemType.TOTAL_REVENUE,
	DashboardItemType.REVENUE_PER_MONTH_TOTAL,
	DashboardItemType.REVENUE_LAST_MONTH_COMPARE,
];

const DashboardItemTypesThataAreOfTypeRevenuePerMonthCompareData:DashboardItemType[] = [
	DashboardItemType.REVENUE_NOW_COMPARE,
	DashboardItemType.PURCHASE_PER_MONTH_COMPARE,
	DashboardItemType.REVENUE_PER_MONTH_COMPARE,
];


export class DashboardItem extends DashboardPlacement{
	Type:DashboardItemType = DashboardItemType.IFRAME;
	OnlyVisibleForAdmin:boolean = false;
	Data:DashboardData = null;
	key:string = v4();

	public constructor(data?:any){
		super(data?.DashboardItemPlacement || data);
		if (!data) return;
		this.Type = data.Type;
		this.OnlyVisibleForAdmin = data.OnlyVisibleForAdmin;
		let minHeight = this.getMinHeight();
		if (this.height < minHeight){
			this.height = minHeight;
		}
		let minWidth = this.getMinWidth();
		if (this.width < minWidth){
			this.width = this.getMinWidth();
		}
		this.createDataObject(data.Data);
	}

	static createNewByType(t:DashboardItemType):DashboardItem{
		let item = new DashboardItem();
		item.Type = t;
		item.createDataObject();
		let smallItemTypes = [DashboardItemType.TOTAL_OPEN, DashboardItemType.TOTAL_PROFIT, DashboardItemType.TOTAL_REVENUE, DashboardItemType.BANK_SALDO];
		if (smallItemTypes.indexOf(t) != -1){
			item.height = 1;
		}
		return item;
	}

	private createDataObject(data?:any){
		if (this.Type == DashboardItemType.YEAR_OVERVIEW_PER_MONTH) {
			this.Data = new DashboardYearOverviewPerMonthData(data);
		}else if (DashboardItemTypesThatAreOfTypeChartData.indexOf(this.Type) != -1) {
			this.Data = new DashboardChartData(data, this.Type);
		}else if (DashboardItemTypesThataAreOfTypeRevenuePerMonthCompareData.indexOf(this.Type) != -1){
			this.Data = new DashboardRevenuePerMonthCompareData(data, this.Type);
		} else if (this.Type == DashboardItemType.IFRAME){
			this.Data = new DashboardIframeData(data);
		} else if (this.Type == DashboardItemType.IMAGE) {
			this.Data = new DashboardImageData(data);
		}else if (this.Type == DashboardItemType.TASK_LIST) {
			this.Data = new DashboardTasksData(data);
		}else if (this.Type == DashboardItemType.BANK_SALDO) {
			this.Data = new DashboardBankData(data, this.Type);
		}else if (this.Type == DashboardItemType.TOTAL_PROFIT){
			this.Data = new DashboardChartDataWithBanks(data);
		}
	}

	getComponentName():string{
		if (this.Type == DashboardItemType.TOTAL_REVENUE) {
			return "TinyInfoTotalRevenue";
		}else if (this.Type == DashboardItemType.TOTAL_OPEN){
			return "TinyInfoTotalOpen";
		}else if (this.Type == DashboardItemType.TOTAL_PROFIT){
			return "TinyInfoTotalProfit";
		}else if (this.Type == DashboardItemType.OPEN_CLIENTS){
			return "OpenClients";
		}else if (this.Type == DashboardItemType.REVENUE_PER_MONTH_COMPARE){
			return "RevenuePerMonthCompare";
		}else if(this.Type == DashboardItemType.REVENUE_PER_MONTH_TOTAL){
			return "RevenuePerMonthTotal";
		}else if (this.Type == DashboardItemType.CASH_IN_FLOW){
			return "CashInflow";
		}else if (this.Type == DashboardItemType.TASK_LIST){
			return "TaskList";
		}else if(this.Type == DashboardItemType.CALENDAR){
			return "Calendar";
		}else if (this.Type == DashboardItemType.IFRAME){
			return "Iframe";
		}else if (this.Type == DashboardItemType.IMAGE) {
			return "Image";
		}else if (this.Type == DashboardItemType.PURCHASE_PER_MONTH_COMPARE) {
			return "PurchasesPerMonthCompare";
		}else if (this.Type == DashboardItemType.REVENUE_NOW_COMPARE) {
			return "RevenueNowCompare";
		}else if (this.Type == DashboardItemType.YEAR_OVERVIEW_PER_MONTH) {
			return "YearOverviewPerMonth";
		}else if (this.Type == DashboardItemType.REVENUE_LAST_MONTH_COMPARE) {
			return "RevenueLastMonthCompare";
		}else if (this.Type == DashboardItemType.BANK_SALDO) {
			return "TinyInfoBankSaldo";
		}
		throw new Error("Invalid item type " + this.Type);
	}

	public getSettingsComponentName():string {
		if (this.Type == DashboardItemType.IFRAME){
			return "IframeSettings";
		}
		if (this.Type == DashboardItemType.IMAGE) {
			return "ImageSettings";
		}
		if (DashboardItemTypesThatAreOfTypeChartData.indexOf(this.Type) != -1) {
			return "ChartDataSettings";
		}
		if (DashboardItemTypesThataAreOfTypeRevenuePerMonthCompareData.indexOf(this.Type) != -1) {
			return "RevenuePerMonthCompareSettings";
		}
		if (this.Type == DashboardItemType.TASK_LIST) {
			return "TaskListSettings";
		}
		if (this.Type == DashboardItemType.YEAR_OVERVIEW_PER_MONTH) {
			return "YearOverviewPerMonthSettings";
		}
		if (this.Type == DashboardItemType.BANK_SALDO) {
			return "BankDataSettings";
		}
		if (this.Type == DashboardItemType.TOTAL_PROFIT){
			return "ChartDataWithBanksSettings";
		}
		return "ERROR";
	}

	public hasSettings():boolean {
		if (this.getSettingsComponentName() != "ERROR"){
			return true;
		}
		return false;
	}

	getMinHeight(): number {
		if (this.Type == DashboardItemType.TOTAL_OPEN){
			return 1;
		}
		if (this.Type == DashboardItemType.TOTAL_PROFIT){
			return 1;
		}
		if (this.Type == DashboardItemType.TOTAL_REVENUE){
			return 1;
		}
		if (this.Type == DashboardItemType.IMAGE) {
			return 1;
		}
		if (this.Type == DashboardItemType.REVENUE_NOW_COMPARE){
			return 1;
		}
		if (this.Type == DashboardItemType.REVENUE_LAST_MONTH_COMPARE) {
			return 1;
		}
		if (this.Type == DashboardItemType.BANK_SALDO) {
			return 1;
		}
		return 3;
	}

	getMinWidth():number {
		//if (this.Type == DashboardItemType.REVENUE_NOW_COMPARE){
		//	return 2;
		//}
		//if (this.Type == DashboardItemType.REVENUE_LAST_MONTH_COMPARE){
		//	return 2;
		//}
		return 1;
	}

	public getJSON():any{
		let data = null;
		if (this.Data){
			data = this.Data.getJSON();
		}
		return {
			...super.getJSON(),
			Type: this.Type,
			OnlyVisibleForAdmin: this.OnlyVisibleForAdmin,
			Data: data
		};
	}

}