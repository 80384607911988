
import { LabelLayoutModel } from "@/models/label-layout/label-layout-model";
import { LabelLayoutService } from "@/services";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";

export default Vue.extend({
	data(){
		return {
			layouts: [] as LabelLayoutModel[]
		};
	},
	props: {
		value: {
			type: Number,
			required: true
		},
		browseId: Number,
		label: [String, Object as ()=>TranslateResult],
		fancy: Boolean,
		includeEmpty: Boolean
	},
	computed: {
		items():ISelectItem<number>[]{
			let result:ISelectItem<number>[]= this.layouts.map(l=>{
				return {
					text: l.Name,
					value: l.ID
				};
			});
			if (this.includeEmpty) {
				result.unshift({
					text: this.$ct("common.empty"),
					value: 0
				});
			}
			return result;
		}
	},
	methods: {
		setValue(val:number){
			this.$emit("input", val);
		}
	},
	async mounted(){
		if (this.browseId) {
			this.layouts = await LabelLayoutService.getByBrowseId(this.browseId);
		}else{
			let result = await LabelLayoutService.fetch();
			this.layouts = result.data;
		}
	}
});
