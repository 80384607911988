export class BookYear{
	static readonly SelectedBookYearValue = "{{SelectedBookYear}}";
	static readonly PreviousBookYearValue = "{{PreviousBookYear}}";

	public ID:number = 0;

	public Year:number = 0;

	public LastAfpuntenSales:Date | null = null;
	public LastCopyOpenSales:Date | null = null;
	public LastAfpuntenPurchases:Date | null = null;
	public LastCopyOpenPurchases:Date | null = null;
	public LastFillLedgersInSaleDetail:Date | null = null;
	public LastIntraConsignment:Date | null = null;
	public LastVatDeclaration:Date | null = null;
	public LastBeginBankCreateInNewBookyear:Date | null = null;
	public LastComputeStock:Date | null = null;

	public constructor(data?:any){
		if(!data){
			return;
		}
		this.ID = data.ID;
		this.Year = data.Year;
		this.LastAfpuntenSales = data.LastAfpuntenSales ? new Date(data.LastAfpuntenSales) : null;
		this.LastCopyOpenSales = data.LastCopyOpenSales ? new Date(data.LastCopyOpenSales) : null;
		this.LastAfpuntenPurchases = data.LastAfpuntenPurchases ? new Date(data.LastAfpuntenPurchases) : null;
		this.LastCopyOpenPurchases = data.LastCopyOpenPurchases ? new Date(data.LastCopyOpenPurchases) : null;
		this.LastFillLedgersInSaleDetail = data.LastFillLedgersInSaleDetail ? new Date(data.LastFillLedgersInSaleDetail) : null;
		this.LastIntraConsignment = data.LastIntraConsignment ? new Date(data.LastIntraConsignment) : null;
		this.LastVatDeclaration = data.LastVatDeclaration ? new Date(data.LastVatDeclaration) : null;
		this.LastBeginBankCreateInNewBookyear = data.LastBeginBankCreateInNewBookyear ? new Date(data.LastBeginBankCreateInNewBookyear) : null;
		this.LastComputeStock = data.LastComputeStock ? new Date(data.LastComputeStock) : null;
	}


}
