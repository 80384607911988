import { isStringTrue } from "@/utils/strings";

export class BankCtepSaleResult {
	BankID:number = 0;
	BookYear:number = 0;
	AcquirerID:string = ""
	AcquirerToken:string = ""
	Action:string = ""
	AdditionalAmount:number | null = null
	AuthorizationCode:string = "";
	AuthorizedAmount:number = 0.0;
	CardBrandIdentifier:string = ""
	CardBrandName:string = ""
	ClientTicket:string = "";
	ClippedPan:string = ""
	IsAsynchronousChip:boolean = false;
	IsBarCode:boolean = false;
	IsContactLess:boolean = false;
	IsIccEmv:boolean = false;
	IsIccNonEmv:boolean = false;
	IsMagneticStripe:boolean = false;
	IsManualEntry:boolean = false;
	IsSynchronousChip:boolean = false;
	Language:string = ""
	MerchantText:string = ""
	MerchantTicket:string = ""
	PartialApproval:boolean | null = null;
	SignatureRequired:boolean = false;
	TerminalIdentifier:string = ""
	TimeStamp:null | Date = null;

	// eslint-disable-next-line max-lines-per-function
	public constructor(data?:any){
		if (!data) return;
		this.BankID = data.BankID;
		this.BookYear = data.BookYear;
		this.AcquirerID = data.AcquirerID;
		this.AcquirerToken = data.AcquirerToken;
		this.Action = data.Action;
		if (typeof(data.AdditionalAmount) == "string"){
			this.AdditionalAmount = parseFloat(data.AdditionalAmount || "0.0");
		}else{
			this.AdditionalAmount = data.AdditionalAmount;
		}
		this.AuthorizationCode = data.AuthorizationCode;
		if (typeof(data.AuthorizedAmount) == "string"){
			this.AuthorizedAmount = parseFloat(data.AuthorizedAmount || "0.0");
		}else{
			this.AuthorizedAmount = data.AuthorizedAmount;
		}
		console.log(this.AuthorizedAmount);
		this.CardBrandIdentifier = data.CardBrandIdentifier;
		this.CardBrandName = data.CardBrandName;
		this.ClientTicket = data.ClientTicket;
		this.ClippedPan = data.ClippedPan;
		this.IsAsynchronousChip = isStringTrue(data.IsAsynchronousChip);
		this.IsBarCode = isStringTrue(data.IsBarCode);
		this.IsContactLess = isStringTrue(data.IsContactLess);
		this.IsIccEmv = isStringTrue(data.IsIccEmv);
		this.IsIccNonEmv = isStringTrue(data.IsIccNonEmv);
		this.IsMagneticStripe = isStringTrue(data.IsMagneticStripe);
		this.IsManualEntry = isStringTrue(data.IsManualEntry);
		this.IsSynchronousChip = isStringTrue(data.IsSynchronousChip);
		this.Language = data.Language;
		this.MerchantText = data.MerchantText;
		this.MerchantTicket = data.MerchantTicket;
		if (data.PartialApproval != null){
			this.PartialApproval = isStringTrue(data.PartialApproval);
		}
		this.SignatureRequired = isStringTrue(data.SignatureRequired);
		this.TerminalIdentifier = data.TerminalIdentifier;
		if (data.TimeStamp) {
			try{
				this.TimeStamp = new Date(data.TimeStamp);
			}catch(err){

			}
		}
	}

	public getJSON(){
		let timeStamp:any = this.TimeStamp;
		if (this.TimeStamp) {
			timeStamp = this.TimeStamp?.toJSON();
		}
		return {
			BankID: this.BankID,
			BookYear: this.BookYear,
			AcquirerID: this.AcquirerID,
			AcquirerToken: this.AcquirerToken,
			Action: this.Action,
			AdditionalAmount: this.AdditionalAmount,
			AuthorizationCode: this.AuthorizationCode,
			AuthorizedAmount: this.AuthorizedAmount,
			CardBrandIdentifier: this.CardBrandIdentifier,
			CardBrandName: this.CardBrandName,
			ClientTicket: this.ClientTicket,
			ClippedPan: this.ClippedPan,
			IsAsynchronousChip: this.IsAsynchronousChip,
			IsBarCode: this.IsBarCode,
			IsContactLess: this.IsContactLess,
			IsIccEmv: this.IsIccEmv,
			IsIccNonEmv: this.IsIccNonEmv,
			IsMagneticStripe: this.IsMagneticStripe,
			IsManualEntry: this.IsManualEntry,
			IsSynchronousChip: this.IsSynchronousChip,
			Language: this.Language,
			MerchantText: this.MerchantText,
			MerchantTicket: this.MerchantTicket,
			PartialApproval: this.PartialApproval,
			SignatureRequired: this.SignatureRequired,
			TerminalIdentifier: this.TerminalIdentifier,
			TimeStamp: timeStamp,
		};
	}
}