
import { Product } from "@/models/base";
import Vue from "vue";
import AppLabelLayoutSelect from "@/components/label-layout/LabelLayoutSelect.vue";

export default Vue.extend({
	props: {
		value: {
			type: Number,
			required: true
		},
		locationId: {
			type: Number,
			required: true
		},
		product: {
			type: Product,
			required: true
		},
		layoutId: {
			type: Number,
			required: true
		}
	},
	computed: {
		shownValue():number {
			if (this.value == -1) {
				return 0;
			}
			return this.value;
		},
		max():number | undefined {
			if (!this.product.LabelSettings) return undefined;
			if (this.product.LabelSettings.IsOneLabelProduct) {
				return 1;
			}
			return undefined;
		}
	},
	watch: {
		"this.product.LabelSettings.IsOneLabelProduct": {
			immediate: true,
			handler(isOneLabelProduct:boolean){
				if (isOneLabelProduct && this.value > 1) {
					this.setValue(1);
				}
			}
		}
	},
	methods: {
		setEnabled(value:boolean){
			if (!value) {
				this.$emit("input", -1);
			}else{
				this.$emit("input", 0);
			}
		},
		setValue(value:number){
			this.$emit("input", value);
		},
		setLayoutId(value:number) {
			this.$emit("update:layoutId", value);
		}
	},
	components: {
		AppLabelLayoutSelect
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
