
import { i18n } from "@/setup/i18n-setup";
import { ILayoutEntity } from "../layout-editor";
import { Field } from "./field";
import { FreeBlock } from "./free-block";
import { StringStyledPropertyWithFreeLine } from "./string-styled-property-with-free-line";
import { TextStyledProperty } from "./text-styled-property";

export class DocumentLayoutClientHeading implements ILayoutEntity {
	public Property1: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property2: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property3: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property4: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property5: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property6: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property7: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property8: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public Property9: StringStyledPropertyWithFreeLine = new StringStyledPropertyWithFreeLine();
	public X = 0;
	public Y = 0;
	public Width = 50;
	public Height = 50;
	public Show = true;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		for (let i = 1; i <= 9; i++) {
			(this as any)["Property" + i] = new StringStyledPropertyWithFreeLine(data["Property" + i]);
		}
		this.X = data.X;
		this.Y = data.Y;
		this.Width = data.Width;
		this.Height = data.Height;
		this.Show = data.Show;
	}
	public getJSON() {
		let data = {
			X: this.X,
			Y: this.Y,
			Width: this.Width,
			Height: this.Height,
			Show: this.Show,
		} as any;
		for (let i = 1; i <= 9; i++) {
			data["Property" + i] = (this as any)["Property" + i].getJSON();
		}
		return data;
	}

	private static lineToField(value:StringStyledPropertyWithFreeLine, headingType:"InvoiceAddress" | "DeliveryAddress" | "Reminder"):Field{
		let result = new Field();
		if (value.Value == "FreeLine") {
			result.Text = value.FreeLineText;
		}else if (value.Value){
			let prefix = "Contact";
			if (headingType == "InvoiceAddress") {
				prefix = "InvoiceAddress";
			}else if (headingType == "DeliveryAddress") {
				prefix = "DeliveryAddress";
			}
			result.Prop = prefix + value.Value;
		}
		result.Style = new TextStyledProperty(value.Style);
		return result;
	}

	toFreeBlock(headingType:"InvoiceAddress" | "DeliveryAddress" | "Reminder"):FreeBlock {
		let result = new FreeBlock();
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property1, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property2, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property3, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property4, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property5, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property6, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property7, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property8, headingType));
		result.Lines.push(DocumentLayoutClientHeading.lineToField(this.Property9, headingType));
		result.X = this.X;
		result.Y = this.Y;
		result.Width = this.Width;
		result.Height = this.Height;
		result.Placement = "Heading";
		result.Name = i18n.t("components.sale-layout-editor.clientAddress").toString();
		if (headingType == "DeliveryAddress") {
			result.Name = i18n.t("components.sale-layout-editor.deliveryAddress").toString();
		}
		this.Show = false;
		return result;
	}
}
