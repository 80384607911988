import { AuthService } from "@/services/auth-service";
import Axios from "axios";
import { v4 } from "uuid";
import { BrowserInfo } from "../browser";

const host = BrowserInfo.getElectronUrl();
export interface IElectronPrinter {
	deviceId:string;
	name:string;
}

export interface IElectronFileEntry{
	name:string;
	path:string;
	isDirectory:boolean;
}

let nativeWindowOpen:any = window.open;
function windowOpenOverride(...args:any):any{
	if (typeof(args[0]) == "string" && args[1] == "_blank"){
		if (args[0].charAt(0) == "/") {
			let url = new URL(`${window.location.origin}${args[0]}`);
			url.searchParams.append("electronPort", BrowserInfo.getElectronPort());
			let event = new CustomEvent("open-new-tab", {
				detail: url.toString()
			});
			window.parent.document.dispatchEvent(event);
			return null;
		}
	}
	return nativeWindowOpen(...args);
}

class ElectronFunctionsClass {
	getDefaultPrinterIdForSaleLayout(saleLayoutId:number):string {
		return localStorage.getItem(`electron-sale-layout-default-printer-${AuthService.wfDossier.id}-${saleLayoutId}`) || "";
	}

	setDefaultPrinterIdForSaleLayout(saleLayoutId:number, value:string){
		let key = `electron-sale-layout-default-printer-${AuthService.wfDossier.id}-${saleLayoutId}`;
		if (!value) {
			localStorage.removeItem(key);
		}else{
			localStorage.setItem(key, value);
		}
	}

	getDefaultPrinterIdForLabelLayout(labelLayoutId:number):string {
		return localStorage.getItem(`electron-label-layout-default-printer-${AuthService.wfDossier.id}-${labelLayoutId}`) || "";
	}

	setDefaultPrinterIdForLabelLayout(labelLayoutId:number, value:string){
		let key = `electron-label-layout-default-printer-${AuthService.wfDossier.id}-${labelLayoutId}`;
		if (!value) {
			localStorage.removeItem(key);
		}else{
			localStorage.setItem(key, value);
		}
	}

	getCopyCountForSaleLayout(saleLayoutId:number):number{
		let res = parseInt(localStorage.getItem(`electron-sale-layout-default-copy-count-${AuthService.wfDossier.id}-${saleLayoutId}`) || "0");
		if (Number.isNaN(res) || res == 0){
			return 1;
		}
		return res;
	}

	setCopyCountForSaleLayout(saleLayoutId:number, copy:number){
		let key = `electron-sale-layout-default-copy-count-${AuthService.wfDossier.id}-${saleLayoutId}`;
		if (copy < 2){
			localStorage.removeItem(key);
		}else{
			localStorage.setItem(key, `${copy}`);
		}
	}

	getDefaultPrinterIdForReminderLayout(reminderLayoutId:number):string {
		return localStorage.getItem(`electron-reminder-layout-default-printer-${AuthService.wfDossier.id}-${reminderLayoutId}`) || "";
	}

	setDefaultPrinterIdForReminderLayout(reminderLayoutId:number, value:string){
		let key = `electron-reminder-layout-default-printer-${AuthService.wfDossier.id}-${reminderLayoutId}`;
		if (!value) {
			localStorage.removeItem(key);
		}else{
			localStorage.setItem(key, value);
		}
	}

	setDownloadFolderForSaleType(saleTypeId:number, path:string | null){
		let key = `electron-dossier-${AuthService.wfDossier.id}-sale-type-${saleTypeId}-download-path`;
		if (path) {
			if (path.endsWith("/")){
				path = path.substring(0, path.length-2);
			}
			localStorage.setItem(key, path);
		}else{
			localStorage.removeItem(key);
		}
	}

	getDownloadFolderForSaleType(saleTypeId:number):string | null {
		let key = `electron-dossier-${AuthService.wfDossier.id}-sale-type-${saleTypeId}-download-path`;
		return localStorage.getItem(key);
	}

	getCopyCountForReminderLayout(reminderLayoutId:number):number{
		let res = parseInt(localStorage.getItem(`electron-reminder-layout-default-copy-count-${AuthService.wfDossier.id}-${reminderLayoutId}`) || "0");
		if (Number.isNaN(res) || res == 0){
			return 1;
		}
		return res;
	}

	setCopyCountForReminderLayout(reminderLayoutId:number, copy:number){
		let key = `electron-reminder-layout-default-copy-count-${AuthService.wfDossier.id}-${reminderLayoutId}`;
		if (copy < 2){
			localStorage.removeItem(key);
		}else{
			localStorage.setItem(key, `${copy}`);
		}
	}

	async saveTempFile(file:File):Promise<string> {
		let form = new FormData();
		form.set("file", file, file.name);
		await Axios.post(`${host}/fs/temp-file`, form, {headers: {
			"Content-Type": "form/multipart"
		}});
		return `${host}/fs/temp-file/${file.name}`;
	}

	async deleteTempFile(fileName:string):Promise<void> {
		await Axios.delete(`${host}/fs/temp-file/${fileName}`);
	}

	async printTempFile(fileName:string):Promise<void> {
		await Axios.post(`${host}/fs/print-temp-file`, {fileName});
	}

	async getTempFilePath(fileName:string):Promise<string> {
		return (await Axios.get(`${host}/fs/temp-file-path/${fileName}`)).data.path;
	}

	getPdfViewerUrl(fileName:string, autoprint:boolean = false):string {
		return `${host}/extensions/pdf-viewer/web/viewer.html?file=${host}/fs/temp-file/${fileName}?autoprint=${autoprint?"1":"0"}`;
	}

	async getPrinters():Promise<IElectronPrinter[]>{
		let result = await Axios.get(`${host}/printers`);
		return result.data as IElectronPrinter[];
	}

	async printPdf(pdfFile:Blob, printerId:string, copies:number = 0):Promise<void>{
		let fileName = `${v4()}.pdf`;
		let file = new File([pdfFile], fileName, {type: "application/pdf"});
		let deleteUrl = await this.saveTempFile(file);
		let err:any = null;
		try{
			await Axios.post(`${host}/printers/print-temp-file`, {fileName, deviceId: printerId, copies});
		}catch(error){
			err = error;
		}
		Axios.delete(deleteUrl);
		if (err) {
			throw err;
		}
	}

	async printTempFileWithDialog(data:Blob, fileName:string):Promise<void>{
		fileName = `${v4()}.pdf`;
		let fileTemp = data as any;
		fileTemp.lastModifiedDate = new Date();
		fileTemp.name = fileName;
		let file = fileTemp as File;
		let deleteUrl = await this.saveTempFile(file);
		await Axios.post(`${host}/printers/print-temp-file-with-dialog`, {fileName});
		Axios.delete(deleteUrl);
	}

	async getSerialNumber():Promise<{key:string,name:string}>{
		let result = await Axios.get(`${host}/extensions/serial-number`);
		return result.data as any;
	}

	async downloadFileToPath(file:Blob, path:string){
		let body = new FormData();
		body.set("file", file);
		await Axios.post(`${host}/fs/save-file`, body, {params: {filePath: path}});
	}

	async lsDirectory(path:string):Promise<IElectronFileEntry[]>{
		let result = await Axios.get(`${host}/fs/ls`, {params: {path}});
		return result.data;
	}

	async getHomeDir():Promise<string>{
		let result = await Axios.get(`${host}/fs/home-dir`);
		return result.data || "/";
	}


	initializeElectron(){
		window.open = windowOpenOverride;
	}
}

export const ElectronFunctions = new ElectronFunctionsClass();