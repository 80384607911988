import { ServerConfig } from "@/config/config";
import { Job } from "@/models/job";
import { Axios } from "@/utils/axios";
import { DossierConfigService } from "@/services/dossier-config-service";
import { EventEmitter } from "@/utils/event-emitter";
import { IInitAppResponse } from "./init-service";
import { BookYear } from "@/models/book-year";
import { MainSocketService } from "./main-socket-service";

export class BookYearServiceClass extends EventEmitter {
	private readonly url:string = ServerConfig.host + "/bookyear";
	public bookYears:BookYear[] = [];
	public manualCreateNewBookyear:boolean = false;
	private _selectedBookYear:number = 0;

	public constructor(){
		super();
		MainSocketService.on("/bookyear/save", (data:any)=>{
			let by = new BookYear(data);
			for (let i =0; i< this.bookYears.length;i++) {
				let b = this.bookYears[i];
				if (b.ID == by.ID) {
					this.bookYears.splice(i,1);
					let otherBookYears = this.bookYears.splice(i);
					this.bookYears.push(by, ...otherBookYears);
					return;
				}
			}
			this.bookYears.push(by);
			this.bookYears.sort((a,b)=>{
				return a.Year - b.Year;
			});
		});
	}

	public get selectedBookYear():number {
		return this._selectedBookYear;
	}

	public get selectedBookYearObj():BookYear {
		let by = this.bookYears.find(b=>b.Year == this._selectedBookYear);
		if (!by) {
			throw new Error("No selected bookyear?");
		}
		return by;
	}

	public get newestBookYear():BookYear {
		let sortedBookYears = [...this.bookYears].sort((a, b) => {
			return b.Year - a.Year;
		});
		return sortedBookYears[0];
	}

	public get oldestBookYear():BookYear {
		let sortedBookYears = [...this.bookYears].sort((a, b) => {
			return a.Year - b.Year;
		});
		return sortedBookYears[0];
	}

	public get currentBookYear():BookYear | null{
		let year = this.getBookYearFromDate(new Date());
		for (let bookyear of this.bookYears){
			if (bookyear.Year == year){
				return bookyear;
			}
		}
		return null;
	}


	public get shouldCreateNewBookYear():boolean {
		let newestBookYear = this.newestBookYear;
		if (!newestBookYear) {
			return false;
		}
		let bookYearEndDate = this.getBookYearEndDate(newestBookYear.Year);
		if (new Date() > bookYearEndDate) {
			return true;
		}
		return false;
	}

	public async selectBookYear(bookyear: BookYear): Promise<any> {
		sessionStorage.setItem("selected-book-year", bookyear.Year.toString());
		this._selectedBookYear = bookyear.Year;
		this.emit("bookyear-changed", bookyear);
	}

	public async overwriteStock():Promise<Job> {
		let result = await Axios.post(`${this.url}/overwrite-stock`);
		return new Job(result.data);
	}

	public async postBookYear(copySales:boolean, copyPurchases:boolean, copyStock:boolean, copyBackorderSales:boolean, copyBankSaldos:boolean): Promise<BookYear> {
		let result = await Axios.post(`${this.url}`, {
			CopySales: copySales,
			CopyPurchases: copyPurchases,
			CopyStock: copyStock,
			CopyBackorderSales: copyBackorderSales,
			CopyBankSaldo: copyBankSaldos
		});
		let by = new BookYear(result.data);
		this.bookYears.push(by);
		return by;
	}

	public async createOlderBookYear(confirmYear:number):Promise<BookYear>{
		let result = await Axios.post(`${this.url}/create-older-bookyear/${confirmYear}`);
		let by = new BookYear(result.data);
		this.bookYears.push(by);
		return by;
	}

	public async fetchBookYears(): Promise<BookYear[]> {
		let result = await Axios.get(`${this.url}`, {params: {limit: 100, order: "this.Year", orderDirection: "ASC"}});
		let bookYears = result.data.data.map((data: any) => new BookYear(data));
		this.bookYears = bookYears;
		return bookYears;
	}

	public getNewestBookYear():BookYear{
		let result = this.bookYears.sort((a,b)=>b.Year - a.Year)[0];
		if (!result) return new BookYear();
		return result;
	}

	public getOldestBookYear(){
		let result = this.bookYears.sort((a,b)=>a.Year - b.Year)[0];
		if (!result) return new BookYear();
		return result;
	}

	public getReportTypes():string[]{
		return ["custom"];
	}

	public init(data:IInitAppResponse){
		this.bookYears = data.BookYears;
		let storedBookYear = parseInt(sessionStorage.getItem("selected-book-year") || "0");
		if (!Number.isNaN(storedBookYear) && storedBookYear != 0) {
			this._selectedBookYear = storedBookYear;
		}else{
			let year =  this.getBookYearFromDate(new Date());
			let found = false;
			for (let by of this.bookYears) {
				if (by.Year == year) {
					this._selectedBookYear = year;
					found = true;
				}
			}
			if (!found) {
				this._selectedBookYear = this.newestBookYear.Year;
			}
		}
	}

	public getBookYearFromDate(date:Date = new Date()):number {
		let year = date.getFullYear();
		let startDateOfYear = this.getBookYearStartDate(year);
		let endDateOfYear = this.getBookYearEndDate(year);
		while(startDateOfYear > date) {
			year--;
			if (year == 10000) {
				throw new Error("Infinite loop in year check");
			}
			startDateOfYear = this.getBookYearStartDate(year);
			endDateOfYear = this.getBookYearEndDate(year);
		}

		while(endDateOfYear < date) {
			year++;
			if (year == 0) {
				throw new Error("Infinite loop in year check");
			}
			startDateOfYear = this.getBookYearStartDate(year);
			endDateOfYear = this.getBookYearEndDate(year);
		}

		return year;
	}

	public getBookYearStartDate(bookyear:number):Date{
		let startMonth = DossierConfigService.getGeneralConfig().BookYearStartMonth;
		let startDate = DossierConfigService.getGeneralConfig().BookYearStartDate;
		if (startMonth == 0 && startDate == 1) {
			return new Date(bookyear, 0, 1, 0,0,0,0);
		}
		return new Date(bookyear-1,startMonth, startDate,0,0,0,0);
	}

	public getBookYearEndDate(bookyear:number):Date{
		let date = this.getBookYearStartDate(bookyear);
		date.setMonth(date.getMonth()+12);
		date.setSeconds(date.getSeconds()-1);
		return date;
	}

	public isDateInBookYear(date:Date, bookyear:number):boolean {
		let startDate = this.getBookYearStartDate(bookyear);
		if (date.getTime() < startDate.getTime()){
			return false;
		}
		let endDate = this.getBookYearEndDate(bookyear);
		if (date.getTime() > endDate.getTime()) {
			return false;
		}
		return true;
	}

	public async canDeleteBookYear(bookyear:BookYear):Promise<boolean>{
		let result = await Axios.get(`${this.url}/can-delete/${bookyear.Year}`);
		return result.data;
	}

	public async deleteBookYear(bookyear:BookYear):Promise<void>{
		await Axios.delete(`${this.url}/${bookyear.Year}`);
	}

	public getAccountingPeriodFromDate(date:Date):number {
		date = new Date(date.getTime());
		let startMonth = DossierConfigService.getGeneralConfig().BookYearStartMonth;
		date.setMonth(date.getMonth() - startMonth);
		let startDate = DossierConfigService.getGeneralConfig().BookYearStartDate;
		date.setDate(date.getDate() - (startDate - 1));
		const monthDiff = date.getMonth();
		return monthDiff+ 1;
	}
};

export const BookYearService = new BookYearServiceClass();