import { BrowserInfo } from "@/utils/browser";
import { ElectronFunctions } from "@/utils/electron/electron";
import { printHtml } from "@/utils/pdf";
import { LabelLayoutData } from "./label-layout-data";

export class LabelLayoutModel {
	ID:number = 0;
	Name:string = "Nieuwe label";
	BrowseID:number = 0;
	Data:LabelLayoutData | null = null;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.Name = data.Name;
		this.BrowseID = data.BrowseID;
		if (data.Data) {
			this.Data = new LabelLayoutData(data.Data);
		}
	}

	public getJSON(){
		return {
			ID: this.ID,
			Name: this.Name.trim(),
			BrowseID: this.BrowseID,
			Data: this.Data
		};
	}

	getPrintScriptCode(pageWidth:number, pageHeight:number):string {
		if (BrowserInfo.isElectron() && ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID)){
			return `
				const {ipcRenderer} = require("electron");
				ipcRenderer.send("print", {
					printer:"${ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID)}", 
					closeOnFinish:true, 
					pageSize:{width:${pageWidth}, height:${pageHeight}}
				})
			`;
		}
		return `window.print();parent.postMessage("close", "*");`;
	}

	print(result:string){
		console.log(result);
		if (BrowserInfo.isElectron() && ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID)){
			let printer = ElectronFunctions.getDefaultPrinterIdForLabelLayout(this.ID);
			console.log(printer);
			let w = window.open("", "Label");
			if (!w) return;
			w.document.write(result);
			w.document.close();
		}else{
			printHtml(result);
		}
	}
}