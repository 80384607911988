import { v4 } from "uuid";
import { Model } from "../models/model";
import { BasicTextStyledProperty } from "../documents/basic-text-styled-property";
import { TextStyledProperty } from "../documents/text-styled-property";
import { roundToDecimals } from "../numbers";

export class TextLine {
	key:string = v4();
	PreText:string = "";
	Prop:string = "";
	PostText:string = "";
	PropRounding:number | null = null;
	Style:TextStyledProperty = new TextStyledProperty();
	PreTextStyle:BasicTextStyledProperty = new BasicTextStyledProperty();
	PropStyle:BasicTextStyledProperty = new BasicTextStyledProperty();
	PostTextStyle:BasicTextStyledProperty = new BasicTextStyledProperty();

	public constructor(data?:any) {
		if(!data) return;
		this.PreText = data.PreText;
		this.Prop = data.Prop;
		this.PostText = data.PostText;
		this.PropRounding = data.PropRounding;
		this.Style = new TextStyledProperty(data.Style);
		this.PreTextStyle = new BasicTextStyledProperty(data.PreTextStyle);
		this.PropStyle = new BasicTextStyledProperty(data.PropStyle);
		this.PostTextStyle = new BasicTextStyledProperty(data.PostTextStyle);
	}

	public getPreloads(model:Model):string[] {
		if (!this.Prop) return [];
		let field = model.getField(this.Prop);
		if (!field) return [];
		if (field.field.isComputed) return [];
		let result:string[] = [];
		if (field.field.dependencies) {
			result.push(...field.field.dependencies);
		}
		let parts = this.Prop.split(".");
		parts.pop();
		let path = parts.join(".");
		if (!path) return result;
		result.push(path);
		return result;
	}

	public getPropName(model:Model):string{
		if (this.Prop){
			let field = model.getField(this.Prop);
			if (field) {
				return field.getPropertyTranslation();
			}
		}
		return "";
	}

	public getAsText(model:Model):string{
		return `${this.PreText}${this.getPropName(model)}${this.PostText}`;
	}

	public getJSON():any{
		return {
			PreText: this.PreText,
			Prop: this.Prop,
			PostText: this.PostText,
			PropRounding: this.PropRounding,
			Style: this.Style.getJSON(),
			PreTextStyle: this.PreTextStyle.getJSON(),
			PropStyle: this.PropStyle.getJSON(),
			PostTextStyle: this.PostTextStyle.getJSON(),
		};
	}

	private computePropText(record:any):string {
		if (!this.Prop) return "";
		let propParts = this.Prop.split(".");
		let propValue = record;
		for (let part of propParts){
			if (!propValue) return "";
			propValue = propValue[part];
		}
		if (this.PropRounding != null && typeof(propValue) == "number") {
			propValue = roundToDecimals(propValue, this.PropRounding);
		}
		return `${propValue}` || "";
	}

	private static setStyleOfEl(html:HTMLElement, s:any){
		for(let prop of Object.keys(s)){
			html.style.setProperty(prop, s[prop]);
		}
	}

	public compute(record:any):string{
		let preText = document.createElement("span");
		preText.innerText = this.PreText;
		TextLine.setStyleOfEl(preText, this.PreTextStyle.getStyle());
		let prop = document.createElement("span");
		prop.innerHTML = this.computePropText(record);
		TextLine.setStyleOfEl(prop, this.PropStyle.getStyle());
		let postText=  document.createElement("span");
		postText.innerText = this.PostText;
		TextLine.setStyleOfEl(postText, this.PostTextStyle.getStyle());
		let div = document.createElement("div");
		TextLine.setStyleOfEl(div, this.Style.getStyle());
		div.appendChild(preText);
		div.appendChild(prop);
		div.appendChild(postText);
		return div.outerHTML;
	}
}