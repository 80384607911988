export function downloadBlob(blob:Blob | File, filename:string) {
	let url = window.URL.createObjectURL(blob);
	let a = document.createElement("a");
	a.href = url;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(url);
}

export function downloadUrl(url:string, filename:string) {
	let a = document.createElement("a");
	a.href = url;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(url);
}


export function base64ToBlob(base64:string, contentType:string):Blob {
	let byteCharacters = atob(base64);
	let byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
	    byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	let byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], {type: contentType});
}

export function openBlobInNewTab(blob:Blob | File){
	console.log(blob.type);
	let url = window.URL.createObjectURL(blob);
	window.open(url, "_blank");
	setTimeout(()=>{
		window.URL.revokeObjectURL(url);
	}, 3000);
}