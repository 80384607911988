import { ServerConfig } from "@/config/config";
import { Browse } from "@/models/browse";
import { Script } from "@/models/script";
import { ViewSetting } from "@/models/user-configs/view-setting";
import { AuthService } from "@/services/auth-service";
import { Axios } from "@/utils/axios";
import { BrowseExtraButton } from "@/utils/browse/browse-extra-button";
import { IInitAppResponse } from "./init-service";
import { BrowseFavouriteColumns } from "@/models/browse-favourite-columns";
import { MainSocketService } from "./main-socket-service";

export interface IBrowseWithExtraData {
	browse: Browse
	viewSetting: ViewSetting
	extraButtons:BrowseExtraButton[]
	scriptButtons: Script[]
}

export class BrowseServiceClass {
	readonly url:string = ServerConfig.host + "/browse";
	private favouriteColumns:BrowseFavouriteColumns[] = [];

	public browses:Browse[] = [];

	get favouriteColumnsUrl():string{
		return `${this.url}/favourite-columns`;
	}

	public constructor(){
		requestAnimationFrame(() => {
			AuthService.on("logout", () => {
				this.browses = [];
			});
		});
	}

	async getBrowses(): Promise<Browse[]> {
		if (this.browses.length > 0) {
			return this.browses;
		}
		this.browses = await this.fetchBrowses();
		return this.browses as Browse[];
	}

	getBrowseWithoutViewSettings(browseId:number):Browse | null{
		let browse = this.browses.find(b=>b.ID == browseId);
		return browse || null;
	}

	async getBrowse(browseID: number): Promise<IBrowseWithExtraData> {
		let result = await Axios.get(`${this.url}/${browseID}`);
		let browse = new Browse(result.data.browse);
		let viewSetting = new ViewSetting(result.data.viewSetting);
		let extraButtons = (result.data.extraButtons || []).map((b:any)=>new BrowseExtraButton(b));
		let scriptButtons = (result.data.scriptButtons || []).map((s:any)=>new Script(s));
		return {
			browse,
			viewSetting,
			extraButtons,
			scriptButtons
		};
	}
	async fetchBrowses():Promise<Browse[]>{
		let result = await Axios.get(this.url);
		return result.data.map((b: any) => new Browse(b));
	}

	async runBrowseScript(browseId:number, scriptId:number, args:string[] = []):Promise<string>{
		let result = await Axios.post(`${this.url}/${browseId}/run-script/${scriptId}`, {Args: args});
		return result.data;
	}

	async updateBrowseFavouriteColumns(columns:BrowseFavouriteColumns):Promise<BrowseFavouriteColumns>{
		let result = await Axios.post(`${this.favouriteColumnsUrl}/${columns.BrowseID}`, columns.getJSON());
		columns =  new BrowseFavouriteColumns(result.data);
		let found = false;
		for (let i in this.favouriteColumns){
			if (this.favouriteColumns[i].BrowseID == columns.BrowseID){
				this.favouriteColumns[i] = columns;
				found = true;
				break;
			}
		}
		if (!found){
			this.favouriteColumns.push(columns);
		}
		return columns;
	}

	getFavouriteColumnsByBrowseId(browseId:number):BrowseFavouriteColumns {
		return this.favouriteColumns.find(b=>b.BrowseID == browseId) || new BrowseFavouriteColumns({BrowseID: browseId});
	}

	onBrowseFavouriteColumnsSaved(b:any){
		let cols = new BrowseFavouriteColumns(b);
		for (let c = this.favouriteColumns.length-1; c>=0;c--){
			if (this.favouriteColumns[c].BrowseID == cols.BrowseID){
				this.favouriteColumns.splice(c, 1);
			}
		}
		this.favouriteColumns.push(cols);
	}

	init(data:IInitAppResponse){
		this.browses = data.Browses;
		this.favouriteColumns = data.BrowseFavouriteColumns;
		MainSocketService.on("/browse-favourite-columns-saved", this.onBrowseFavouriteColumnsSaved.bind(this));
	}

};

export const BrowseService = new BrowseServiceClass();