import { i18n } from "@/setup/i18n-setup";
import { DashboardItemType } from "./dashboard-item";

export class DashboardBankData {
	public bankId:number = 1;
	public title:string = ""

	public constructor(data?:any, type?:DashboardItemType) {
		if (!data) {
			if (type == DashboardItemType.BANK_SALDO) {
				this.title = i18n.t("components.dashboard.item-type.BANK_SALDO").toString();
			}
			return;
		}
		this.title = data.title;
		this.bankId = data.bankId;
	}

	public getJSON(){
		return {
			bankId: this.bankId,
			title: this.title
		};
	}
}