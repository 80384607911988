
import Vue from "vue";
import { DossierConfigService } from "@/services/dossier-config-service";
import VueI18n from "vue-i18n";
import { ISelectItem } from "winfakt-vue-components";
import { LanguagesConfig } from "@/models/dossier-configs/languages";
import { getTranslatedIsoLangName, getTranslatedIsoLangNames, LanguageNamesDict } from "@/utils/languages";

export default Vue.extend({
	data() {
		return {
			selectedLanguage: "",
			translatedNames: {} as LanguageNamesDict
		};
	},
	props: {
		value: String,
		fancy: Boolean,
		light: Boolean,
		disabled: Boolean,
		label: [String, Object as () => VueI18n.TranslateResult],
		short: Boolean,
		addEmptyOption: Boolean,
		emptyOptionText: [String, Object as ()=>VueI18n.TranslateResult],
		up: Boolean
	},
	computed: {
		languages():LanguagesConfig{
			return DossierConfigService.getLanguages();
		},
		languageItems(): ISelectItem<string>[] {
			let items:ISelectItem<string>[] = this.languages.LanguageNames.map((l) => {
				return {
					text: this.short ? l : getTranslatedIsoLangName(l, this.translatedNames),
					value: l
				};
			});
			if (this.addEmptyOption) {
				items.unshift({
					text: this.emptyOptionText || this.$ct("common.empty"),
					value: ""
				});
			}
			return items;
		},
		_label(): String | VueI18n.TranslateResult {
			if (!this.label) {
				return "";
			}
			return this.label;
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(value: string) {
				if (!this.addEmptyOption) {
					if (value == "") return;
					if (value == this.selectedLanguage) return;
					this.selectedLanguage = value;
				}
			}
		},
		selectedLanguage: {
			handler(value: string) {
				if (value == this.value) return;
				this.$emit("input", value);
			}
		}
	},
	async mounted() {
		this.translatedNames = await getTranslatedIsoLangNames();
		if (this.addEmptyOption){
			this.selectedLanguage = this.value;
		} else if (this.selectedLanguage == "" && this.languages.LanguageNames.length > 0 ) {
			this.selectedLanguage = this.languages.LanguageNames[0];
			this.$emit("input", this.selectedLanguage);
		}
	},
});
