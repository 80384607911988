
import Vue from "vue";
import AppPageSettingsDefault from "@/components/document-settings/PageSettingsDefault.vue";
import { DocumentPageSettings } from "@/utils/documents/page-settings";
import {BrowseStateBase} from "@/utils/browse/browse-state-base";
import { IPrintBrowseSettings, IPrintBrowseType } from "@/utils/browse/browse-to-file";

export default Vue.extend({
	data(){
		return {
			pageSettings: new DocumentPageSettings(),
			fileName: "",
			title: "",
			outputType: "pdf" as IPrintBrowseType,
			replaceColumnNamesWithProductCsvNames: false,
			replaceColumnNamesWithContactCsvNames: false
		};
	},
	props: {
		state: {
			type: Object as ()=>BrowseStateBase,
			required: true
		}
	},
	computed: {
		isContactView():boolean {
			return this.state.browse.Table == "Contact";
		},
		isProductView():boolean {
			return this.state.browse.Table == "Product";
		}
	},
	methods: {
		cancel(){
			this.$emit("close");
		},
		confirm(){
			this.$emit("confirm", {
				Type: this.outputType,
				PageSettings: this.pageSettings,
				selectedOnly: this.state.selectedItems.length>0,
				fileName: this.fileName,
				title: this.title,
				replaceColumnNamesWithProductCsvNames: this.replaceColumnNamesWithProductCsvNames,
				replaceColumnNamesWithContactCsvNames: this.replaceColumnNamesWithContactCsvNames,
			} as IPrintBrowseSettings);
			localStorage.setItem("print-browse-page-settings", JSON.stringify(this.pageSettings.getJSON()));
		}
	},
	created(){
		let settingsJson = localStorage.getItem("print-browse-page-settings");
		if (settingsJson){
			this.pageSettings = new DocumentPageSettings(JSON.parse(settingsJson));
		}else{
			this.pageSettings.FooterHeight = 0;
			this.pageSettings.HeadingHeight = 15;
			this.pageSettings.MarginLeft = 15;
			this.pageSettings.MarginRight = 15;
			this.pageSettings.MarginTop = 5;
		}
		this.fileName = this.state.querySettings.view.Name;
		this.title = this.fileName;
	},
	components: {
		AppPageSettingsDefault
	},
	i18nEx: {
		componentPrefix: "components.browse"
	}
});
