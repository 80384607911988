import { EventInput } from "@fullcalendar/core/structs/event";
import { Calendar } from "./calendar";
import { Sale } from "./base";
import {UserConfigService} from "@/services";

export enum GetColorMethod {
	NONE = "",
	SALE_DELIVERY_ADDRESS_POSTAL_CODE = "saly-delivery-address-postal-code",
	COLOR_FIELD = "color-field"
}

export class CalendarEvent {
	ID: number = 0;
	Title: string = "";
	StartTime: Date = new Date();
	EndTime: Date = new Date();
	CalendarID: number = 0;
	Calendar: Calendar | null = null;
	AllDay: boolean = false;
	SaleID:number = 0;
	SaleBookYear:number = 0;
	Sale:Sale | null = null;
	StartTimeIsLinkedToSaleDeliveryDate:boolean = false;
	Color:string = "";
	GetColorMethod:GetColorMethod = GetColorMethod.NONE;

	constructor(data?: any) {
		if (!data) {
			return;
		}
		this.ID = data.ID;
		this.Title = data.Title;
		this.StartTime = new Date(data.StartTime);
		this.EndTime = new Date(data.EndTime);
		this.CalendarID = data.CalendarID;
		this.AllDay = data.AllDay;
		this.SaleID = data.SaleID;
		this.SaleBookYear = data.SaleBookYear;
		this.StartTimeIsLinkedToSaleDeliveryDate = data.StartTimeIsLinkedToSaleDeliveryDate;
		this.Color = data.Color || "";
		this.GetColorMethod = data.GetColorMethod || GetColorMethod.NONE;
		if (data.Calendar) {
			this.Calendar = new Calendar(data.Calendar);
		}
		if (data.Sale) {
			this.Sale = new Sale(data.Sale);
		}
	}

	getJSON() {
		return {
			ID: this.ID,
			Title: this.Title,
			AllDay: this.AllDay,
			StartTime: this.StartTime.toJSON(),
			EndTime: this.EndTime.toJSON(),
			CalendarID: this.CalendarID,
			SaleID: this.SaleID,
			SaleBookYear: this.SaleBookYear,
			StartTimeIsLinkedToSaleDeliveryDate: this.StartTimeIsLinkedToSaleDeliveryDate,
			Color: this.Color,
			GetColorMethod: this.GetColorMethod
		};
	}

	private static readonly colors: string[] = [
		"#1A237E", "#3F51B5",
		"#1B5E20", "#388E3C", "#9E9D24",
		"#FF6F00", "#BF360C",
		"#880E4F", "#E91E63",
		"#FF1744", "#C62828",
		"#3E2723", "#6D4C41",
		"#1B1F23", "#546E7A",
		"#3498DB", "#2C3E50",
		"#0D47A1", "#1976D2", "#64B5F6",
		"#004D40", "#2E7D32", "#76FF03",
		"#FFEB3B", "#FFA000", "#FF5722",
		"#B71C1C", "#F44336", "#D50000",
		"#4A148C", "#7C4DFF", "#AA00FF",
		"#BDBDBD", "#757575", "#263238"
	];

	public setEndTime(endTime: Date){

		let e = new Date(endTime);
		let settings = UserConfigService.getCalendarSettings();

		if(settings.DefaultEventDuration == 0.1){
			this.AllDay = true;
			return;
		}

		if(settings.DefaultEventDuration < 1){
			e.setMinutes(e.getMinutes() + settings.DefaultEventDuration * 100);
			this.EndTime = e;
			return;
		}

		e.setHours(e.getHours() + settings.DefaultEventDuration);
		this.EndTime = e;
		return;

	}

	private getColorOverride():string {
		if (this.GetColorMethod == GetColorMethod.NONE) {
			return "";
		}
		if (this.GetColorMethod == GetColorMethod.COLOR_FIELD) {
			return this.Color;
		}
		if (this.GetColorMethod == GetColorMethod.SALE_DELIVERY_ADDRESS_POSTAL_CODE) {
			if (!this.Sale) return "";
			let postal = "";
			if (!this.Sale.DeliveryAddress || this.Sale.DeliveryAddress.Postal == "") {
				if (!this.Sale.InvoiceAddress) return "";
				postal = this.Sale.InvoiceAddress.Postal;
			}else{
				postal = this.Sale.DeliveryAddress.Postal;
			}
			return this.getColorForPostcode(postal);
			/*let hash = stringToHash(postal);
			let index = parseInt(hash.toString().split("").pop() || "0");
			return CalendarEvent.colors[index];*/
		}
		return "";
	}
	private hashStringToIndex(input: string, max: number): number {
		let hash = 0;
		for (let i = 0; i < input.length; i++) {
			hash = ((hash << 5) - hash + input.charCodeAt(i)) & 0xffffffff; // Bitwise operations
		}
		return Math.abs(hash % max);
	}

	private getColorForPostcode(postcode: string): string {
		const index = this.hashStringToIndex(postcode, CalendarEvent.colors.length);
		return CalendarEvent.colors[index];
	}

	toFullCalendar(): EventInput {
		return {
			id: this.ID,
			title: this.Title,
			start: this.StartTime,
			end: this.EndTime,
			allDay: this.AllDay,
			url: "", // ServerConfig.host + "/calendar-event/" + this.ID,
			location: "",
			description: this.Title,
			extendedProps: {
				id: this.ID,
				isGoogle: false,
				start: this.StartTime,
				end: this.EndTime,
				sale: this.Sale,
				colorOverride: this.getColorOverride(),
			}
		};
	}
}