
import { ContactAddress } from "@/models/base";
import { DossierConfigService } from "@/services/dossier-config-service";
import { getZipcodes, IZipCode } from "@/utils/zip-code";
import Vue from "vue";
import { getContactServiceByGroupId } from "../../utils/services/contact-services";
import AppCountryCodeAutocomplete from "@/components/CountryCodeAutocomplete.vue";
import AppCustomLanguageSelect from "@/components/CustomLanguageSelect.vue";
import {AuthService} from "@/services";
import {MODULE} from "@/config/modules";
import { ContactsConfig } from "@/models/dossier-configs/contacts";

let ZipCodes = [] as IZipCode[];

export default Vue.extend({
	data() {
		return {
			country: "",
			multiPostalSelect: false,
			foundPostalCodes: [] as IZipCode | IZipCode[],
			originalCompanyName: "",
			originalAddressline1: "",
			labelInput: "",
			config: DossierConfigService.getContactsConfig() as ContactsConfig
		};
	},
	props: {
		contactAddress: {type: Object as () => ContactAddress},
		groupId: {
			type: Number,
			required: true
		},
		hideLabel: Boolean
	},
	computed: {
		lngs: () => DossierConfigService.getLanguages().LanguageNames,
		hasMultiLanguages(): boolean {
			if (!AuthService.hasModule(MODULE.MULTITALEN)) {
				return false;
			}
			return true;
		}
	},
	methods: {
		async addressBlur(){
			if(this.config.CheckForDuplicateAddress && this.contactAddress?.AddressLine1){
				if (this.contactAddress.ContactID != 0) {
					if (this.originalAddressline1 != "" && (this.originalAddressline1 == this.contactAddress.AddressLine1)) {
						return;
					}
				}
				let result = await getContactServiceByGroupId(this.groupId).contactAddressExists(this.contactAddress?.AddressLine1);
				if (result.length == 0) {
					return;
				}
				let foundSelf = false;
				for (let item of result){
					if (item.ID == this.contactAddress.ContactID){
						foundSelf = true;
						break;
					}
				}
				if (!foundSelf) {
					let confirm = await this.$wf.confirm(this.$ct("exists_confirm").toString(),
						this.$t("common.yes").toString(), this.$t("common.no").toString(),
						this.$t("common.warning").toString(), "warning");

					if (!confirm) {
						(this.$refs.AddressLine1 as any).focus();
					} else {
						(this.$refs.AddressLine2 as any).focus();
					}
				}
			}
		},
		postalBlur() {
			let result = ZipCodes.filter(zip => zip.zip == this.contactAddress.Postal);
			if (!result.length) return;

			if (result.length == 1) {
				this.contactAddress.City = result[0].city;
				this.contactAddress.CountryCode = result[0].countryCode;
			} else {
				this.multiPostalSelect = true;
				this.foundPostalCodes = result;
			}
		},
		cityBlur() {
			console.log(this.contactAddress.Postal);
			if (this.contactAddress.Postal) return;
			let result = ZipCodes.find(zip => zip.city.trim().toLowerCase() == this.contactAddress.City.trim().toLowerCase());
			if (!result) return;
			this.contactAddress.Postal = result.zip;
			this.contactAddress.CountryCode = result.countryCode;
		},
		chooseCity(city: string) {
			this.contactAddress.City = city;
			this.multiPostalSelect = false;
			this.foundPostalCodes = [];
		},
		async companyNameBlur() {
			if (this.contactAddress.ContactID != 0) {
				if (this.originalCompanyName != "" && (this.originalCompanyName == this.contactAddress.CompanyName)) {
					return;
				}
			}
			if (this.contactAddress.CompanyName) {
				let result = await getContactServiceByGroupId(this.groupId).contactExists(this.contactAddress.CompanyName);
				if (result.length == 0) {
					return;
				}
				let foundSelf = false;
				for (let item of result){
					if (item.ID == this.contactAddress.ContactID){
						foundSelf = true;
						break;
					}
				}
				if (!foundSelf) {
					let confirm = await this.$wf.confirm(this.$ct("exists_confirm").toString(),
						this.$t("common.yes").toString(), this.$t("common.no").toString(),
						this.$t("common.warning").toString(), "warning");

					if (!confirm) {
						(this.$refs.CompanyName as any).focus();
					} else {
						(this.$refs.Firstname as any).focus();
					}
				}
			}
		}
	},
	async created(){
		ZipCodes = await getZipcodes();
		if (this.contactAddress.CompanyName) {
			this.originalCompanyName = this.contactAddress.CompanyName;
		}
		if (this.contactAddress?.AddressLine1){
			this.originalAddressline1 = this.contactAddress.AddressLine1;
		}
	},
	components: {
		AppCountryCodeAutocomplete,
		AppCustomLanguageSelect
	},
	i18nEx: {
		componentPrefix: "components.contacts"
	}
});
