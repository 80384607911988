
import Vue from "vue";
import AppSaleSearch from "@/components/sales/SaleSearch.vue";
import { TranslateResult } from "vue-i18n";
import { v4 } from "uuid";
import { SaleService } from "@/services/sale-service";
import { Sale } from "@/models/base";

export default Vue.extend({
	data(){
		return {
			sales: [] as Sale[],
			fetchThreadId: v4()
		};
	},
	props: {
		value: {
			type: [String, Array as ()=>string[]],
			required: true
		},
		multi: Boolean,
		label: {
			type: [Object as ()=>TranslateResult, String],
			default():TranslateResult{
				if (this.multi) return this.$ct("components.sales.sales");
				return this.$ct("components.sales.sale");
			}
		}
	},
	computed: {
		singleValue():Sale | null {
			return this.sales[0];
		},
		nummericValues():number[]{
			if (typeof(this.value) == "string"){
				let val = parseInt(this.value || "0");
				if (Number.isNaN(val)){
					val = 0;
				}
				if (val == 0){
					return [];
				}
				return [val];
			}
			return this.value.map(v=>{
				let val = parseInt(v || "0");
				if (Number.isNaN(val)){
					val = 0;
				}
				return val;
			}).filter(v=>v>0);
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(){
				this.checkAndFetchSales();
			}
		}
	},
	methods: {
		async checkAndFetchSales(){
			let threadId = v4();
			this.fetchThreadId = threadId;
			let storedSales = [...this.sales];
			let notFoundSaleIds = [];
			for (let val of this.nummericValues) {
				let sale = storedSales.find(f=>f.ID == val);
				if (!sale){
					notFoundSaleIds.push(val);
				}
			}

			if (notFoundSaleIds.length > 0){
				let fetchedSales = await SaleService.getSalesByIds(notFoundSaleIds);
				storedSales.push(...fetchedSales);
			}
			if (this.fetchThreadId != threadId){
				return;
			}
			this.sales = this.nummericValues.map(id=>storedSales.find(s=>s.ID == id) || this.getErrorSale());
		},
		getErrorSale():Sale{
			let s = new Sale();
			s.ComputedFriendlyID = "ERROR";
			return s;
		},
		setSingleValue(value:Sale){
			this.$emit("input", `${value.ID}`);
		},
		addSale(value:Sale){
			let foundSale = this.sales.find(c=>c.ID == value.ID);
			if (foundSale) return;
			this.sales.push(value);
			let val = this.sales.map(v=>`${v.ID}`).filter(v=>v != "0");
			this.$emit("input", val);
		},
		removeSale(value:number){
			this.sales.splice(value,1);
			let val = this.sales.map(v=>`${v.ID}`).filter(v=>v != "0");
			this.$emit("input", val);
		}
	},
	components: {
		AppSaleSearch
	},
	i18nEx: {
		componentPrefix: "components.models"
	}
});
