
import Vue from "vue";
import { Sale} from "@/models/base";
import AppSaleSearch from "@/components/sales/SaleSearch.vue";
import {SaleService} from "@/services";


export default Vue.extend({
	data(){
		return {
			cachedSales: [] as Sale[]
		};
	},
	props: {
		value: String,
		label: String,
	},
	computed: {
		itemIds():number[]{
			let items = JSON.parse(this.value || "[]");
			if (!Array.isArray(items)){
				items = [];
			}
			return items;
		},
		items():Sale[]{
			let toFetchSale = [] as number[];
			let result = [] as Sale[];
			for (let id of this.itemIds){
				let sale = this.cachedSales.find(c=>c.ID == id);
				if (!sale){
					toFetchSale.push(id);
				}else{
					result.push(sale);
				}
			}
			this.fetchSales(toFetchSale);
			return result;
		}
	},
	methods: {
		onInput(sale:Sale){
			let foundSale = this.cachedSales.find(c=>c.ID == sale.ID);
			if (!foundSale){
				this.cachedSales.push(sale);
			}
			let foundId = this.itemIds.find(id=>id== sale.ID);
			if (!foundId){
				let itemIds = [...this.itemIds, sale.ID];
				this.$emit("input", JSON.stringify(itemIds));
			}
		},
		removeSale(sale:Sale){
			let itemIds = [...this.itemIds];
			let index = this.itemIds.findIndex(i=>i == sale.ID);
			if (index == -1) return;
			itemIds.splice(index, 1);
			this.$emit("input", JSON.stringify(itemIds));
		},
		async fetchSales(saleIds:number[]){
			if (saleIds.length == 0) return;
			try{
				let result = await SaleService.getSalesByIds(saleIds);
				let toAddSales = [] as Sale[];
				for (let sale of result){
					let foundSale = this.cachedSales.find(c=>c.ID == sale.ID);
					if (foundSale) continue;
					toAddSales.push(sale);
				}
				this.cachedSales.push(...toAddSales);
				let itemIds = [...this.itemIds];
				for (let i = itemIds.length-1;i>=0;i--){
					let id = this.itemIds[i];
					let foundSale = this.cachedSales.find(c=>c.ID == id);
					if (!foundSale){
						itemIds.splice(i, 1);
					}
				}
				this.$emit("input", JSON.stringify(itemIds));
			}catch(err){

			}
		}
	},
	created(){
		if (SaleService.selectedSales.length > 0) {
			this.$emit("input", JSON.stringify(SaleService.selectedSales.map(p => p.ID)));
			return;
		}
		this.$emit("input", "[]");
	},
	components: {
		AppSaleSearch
	}
});
