import { ServerConfig } from "@/config/config";
import { IChartDataPerMonthPerYear, IIncomeChartYear, ChartDataPerMonth } from "@/utils/chart-income";
import { IOpen } from "@/utils/chart-open";
import { Axios } from "@/utils/axios";
import { getPreloadsFromFilters, HumanFilter } from "@/utils/human-filter";

export interface IYearOverviewPerMonthResult{
	income:number;
	cost:number;
	difference:number;
}

export class ChartServiceClass{
	get url(): string {
		return ServerConfig.host + "/charts";
	}

	async getTotalOpen(filters:HumanFilter[], bookyearOverride?:number): Promise<number> {
		let result = await Axios.get(`${this.url}/total-open`, {
			params: {"filters": filters.map(f=>f.getJSON()), preloads: getPreloadsFromFilters(filters)},
			headers: {Bookyear: bookyearOverride}
		});
		return parseFloat(result.data);
	}

	async getTotalExpense(filters:HumanFilter[], bookyearOverride?:number): Promise<number> {
		let result = await Axios.get(`${this.url}/total-expense`, {
			params: {"filters": filters.map(f=>f.getJSON()), preloads: getPreloadsFromFilters(filters)},
			headers: {Bookyear: bookyearOverride}
		});
		return parseFloat(result.data);
	}

	async getTotalIncome(filters:HumanFilter[], bookyearOverride?:number): Promise<number> {
		let result = await Axios.get(`${this.url}/total-income`, {
			params: {"filters": filters.map(f=>f.getJSON()), preloads: getPreloadsFromFilters(filters)},
			headers: {Bookyear: bookyearOverride}
		});
		return parseFloat(result.data);
	}

	async getRevenueToday(bookyears:number[], filters:HumanFilter[]):Promise<{BookYear:number, Revenue:number}[]>{
		let result = await Axios.get(`${this.url}/revenue-today`, {
			params: {"filters": filters.map(f=>f.getJSON()), preloads: getPreloadsFromFilters(filters), bookyears}
		});
		return (result.data as {BookYear:number, Revenue:number}[]).sort((a,b)=>a.BookYear - b.BookYear);
	}

	async getIncome(maxYears:number, filters:HumanFilter[], bookyearOverride?:number): Promise<IChartDataPerMonthPerYear> {
		let result = await Axios.get(`${this.url}/income`, {
			params: {"max-years": maxYears, "filters": filters.map(f=>f.getJSON()), preloads: getPreloadsFromFilters(filters)},
			headers: {Bookyear: bookyearOverride}
		});
		return {
			Years: result.data.Years.map((y:any)=>new ChartDataPerMonth(y as IIncomeChartYear))
		};
	}

	async getExpense(maxYears:number, filters:HumanFilter[]): Promise<IChartDataPerMonthPerYear> {
		let result = await Axios.get(`${this.url}/expense`, {params: {"max-years": maxYears, "filters": filters.map(f=>f.getJSON()), preloads: getPreloadsFromFilters(filters)}});
		return {
			Years: result.data.Years.map((y:any)=>new ChartDataPerMonth(y as IIncomeChartYear))
		};
	}

	async getTotalBank(filters:HumanFilter[]):Promise<number>{
		let preloads = getPreloadsFromFilters(filters);
		let result = await Axios.get(`${this.url}/total-bank`, {params: {"filters": filters.map(f=>f.getJSON()), preloads}});
		return result.data.Result;
	}

	async getOpen(): Promise<IOpen> {
		let result = await Axios.get(this.url + "/open");
		return result.data;
	}

	async getYearOverviewPerMonth(incomeFilters:HumanFilter[], costFilters:HumanFilter[], bookyearOverride?:number):Promise<IYearOverviewPerMonthResult[]>{
		let result = await Axios.get(`${this.url}/year-overview-per-month`, {
			params: {
				"incomeFilters": incomeFilters.map(f=>f.getJSON()),
				"incomePreloads": getPreloadsFromFilters(incomeFilters),
				"costFilters": costFilters.map(f=>f.getJSON()),
				"costPreloads": getPreloadsFromFilters(costFilters),
			},
			headers: {Bookyear: bookyearOverride}
		});
		let list:IYearOverviewPerMonthResult[] = [];
		for (let i = 0; i < 12; i++) {
			let item:IYearOverviewPerMonthResult = {cost: 0.0, difference: 0.0, income: 0.0};
			item.income = result.data.Incomes[i] || 0;
			item.cost = result.data.Costs[i] || 0;
			item.difference = item.income - item.cost;
			list.push(item);
		}
		return list;
	}

	async getRevenueOfMonthCompareHandler(date:Date, filters:HumanFilter[], bookyear?:number):Promise<{ThisMonth:number, LastMonth:number}>{
		let preloads = getPreloadsFromFilters(filters);
		let result = await Axios.get(`${this.url}/revenue-of-month-compare`, {
			params: {date: date.toJSON(), filters: filters.map(f=>f.getJSON()), preloads},
			headers: {Bookyear: bookyear}
		});
		return result.data;
	}
};

export const ChartService = new ChartServiceClass();