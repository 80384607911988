
import AppBrowseTotalItemsResult from "@/components/browse/BrowseTotalItemsResult.vue";
import { ViewSetting } from "@/models/user-configs/view-setting";
import { IBrowseBaseDataSource } from "@/utils/browse/browse-base-data-source";
import { BrowseStateSearch } from "@/utils/browse/browse-state-search";
import Vue from "vue";
import AppFilterModal from "./FilterModal.vue";
import AppBrowseActions from "./SearchBrowseActions.vue";
import AppBrowseSearchTable from "./SearchBrowseTable.vue";
import AppCustomReportCreate from "@/components/reportengine/CreateCard.vue";
import { View } from "@/models/view";
import { BrowserInfo } from "@/utils/browser";
import { ISelectItem } from "winfakt-vue-components";
import {HumanFilter} from "@/utils/human-filter";
import AppBrowseToFileModal from "./BrowseToFileModal.vue";
import AppPrintLabelsModal from "./PrintLabelsModal.vue";

export default Vue.extend({
	data() {
		return {
			initialized: false,
			state: null as null | BrowseStateSearch,
			browseID: 0
		};
	},
	props: {
		urlQueryParams: Boolean,
		dataSource: {
			type: Object as () => IBrowseBaseDataSource<any>,
			required: false
		},
		showReports: {
			type: Boolean,
		},
		showBrowseSettings: {
			type: Boolean,
		},
		multiple: {
			type: Boolean,
		},
		selectedItems: {
			type: Array as () => any[],
			required: false,
			default: () => []
		},
		clearViewSettingsFilters: Boolean,
		allowViewButton: Boolean,
		view: Object as ()=>View,
		buttonBarItems: {
			type: Array as () => ISelectItem<any>[],
			default: ()=>[]
		},
		contextMenuItems: {
			type: Array as () => ISelectItem<string>[],
			default: () => []
		},
		filters: {
			type: Array as () => HumanFilter[],
			default: ()=>[]
		},
		searchString: String,
		overrideStaticViewSelect: Boolean,
		showSelectedItemsCount: Boolean
	},
	computed: {
		isSafari():boolean{
			return BrowserInfo.isSafari();
		}
	},
	watch: {
		dataSource: {
			deep: true,
			handler() {
				this.init();
			}
		},
		"state.selectedItems"() {
			if (this.state)
				this.$emit("select-changed", this.state.selectedItems);
		},
		buttonBarItems() {
			if (this.state) {
				this.state.baseButtonBarItems = this.buttonBarItems;
			}
		},
		contextMenuItems() {
			if (this.state) {
				this.state.contextMenu.items = this.contextMenuItems;
			}
		},
		"state.queryResults.data.length"(){
			if (!this.state)return;
			this.state.checkIfSelectedItemsAreStillInBrowse();
		}
	},
	methods: {
		async init() {
			this.initialized = false;
			this.state = null;
			let result = await this.dataSource.getSettings();
			this.browseID = result.browse.ID;
			if (!result.viewSetting) {
				result.viewSetting = ViewSetting.getDefaultViewSetting();
			}

			this.state = new BrowseStateSearch(this.$wf, this.$router, this.dataSource, result, false, this.selectedItems);
			this.state.multiple = this.multiple;
			this.state.showReportsButton = this.showReports;
			this.state.allowViewButton = this.allowViewButton;
			this.state.baseButtonBarItems = this.buttonBarItems || [];
			this.state.contextMenu.items = this.contextMenuItems;

			let f = this.state.getFilters();

			if (this.clearViewSettingsFilters) {
				this.state.setFilters({filters: [], searchQuery: "", searchByAllColumns: f.searchByAllColumns, autoWildCardSearch: f.autoWildCardSearch});
			}
			if(this.filters?.length > 0){
				this.state.setFilters({filters: this.filters, searchQuery: "", searchByAllColumns: f.searchByAllColumns, autoWildCardSearch: f.autoWildCardSearch});
			}
			if(this.searchString){
				this.state.setFilters({filters: [], searchQuery: this.searchString, searchByAllColumns: f.searchByAllColumns, autoWildCardSearch: f.autoWildCardSearch});
			}
			if (this.view) {
				this.state.setStaticView(this.view);
			}
			if(this.overrideStaticViewSelect){
				this.state.overrideStaticViewSelect = true;
			}
			this.state.on("context-menu-trigger", (item: { value: string, item: any }) => this.$emit(item.value, item.item));
			await this.state.fetch();
			await this.state.fetchReports();
			this.$emit("state", this.state);
			this.initialized = true;
		},
		searchKeyPressed(e: KeyboardEvent) {
			//console.log("SEARCHBROWSE");
			//if (document.getElementsByClassName("modal").length) return;

			if ((e.target as HTMLTextAreaElement).className.indexOf("w-input") >= 0 ||
				(e.target as HTMLTextAreaElement).className.indexOf("w-input-number") >= 0 ||
				(e.target as HTMLTextAreaElement).className.indexOf("ql-editor") >= 0) {
				return;
			}

			if (!this.state) return;

			if (e.altKey || e.ctrlKey || e.metaKey)
				return;

			if (e.keyCode == 27)
				this.state.showSearch = false;

			if (/^[a-zA-Z0-9-_]$/.test(e.key)) {
				this.state.querySettings.searchQuery = "";
				this.state.showSearch = true;
			}
		},
		onClick(item: any) {
			if (!this.multiple) {
				this.$emit("click", item);
			}
		},
		refresh(){
			console.log("refresh");
			if (!this.state) return;
			console.log("no state");
			this.state.fetch();
		}
	},
	async mounted() {
		this.init();
	},
	created() {
		window.addEventListener("keydown", this.searchKeyPressed);
	},
	destroyed() {
		window.removeEventListener("keydown", this.searchKeyPressed);
		if (!this.state)return;
		this.state.destroy();
	},
	components: {
		AppBrowseSearchTable,
		AppFilterModal,
		AppBrowseActions,
		AppBrowseTotalItemsResult,
		AppCustomReportCreate,
		AppBrowseToFileModal,
		AppPrintLabelsModal
	}
});
