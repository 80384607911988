import { ServerConfig } from "@/config/config";
import { Job } from "@/models/job";
import { Axios } from "@/utils/axios";
import {  downloadUrl } from "@/utils/blob";
import axios from "axios";
import { PdfS3UploadedFile } from "@/models/pdf-archive-file";

export interface IPublicUblFile {
	fileInfo: PdfS3UploadedFile;
	userName: string;
	companyName: string;
}

class PdfArchiveServiceClass {
	public readonly publicUrl: string = `${ServerConfig.host}/pdfarchive`;
	public readonly url: string = `${this.publicUrl}/private`;


	public async createArchive(beginDate: Date, endDate: Date, message: string, ids: any): Promise<Job> {
		let result = await Axios.post(`${this.url}/create-archive`, {beginDate, endDate, message, ids});
		return new Job(result.data);
	}

	public async downloadArchive(file: PdfS3UploadedFile): Promise<void> {
		let result = await Axios.get(`${this.url}/archives/${file.ID}/download`);
		let url = result.data.Url;
		downloadUrl(url, file.FileName);
	}

	public async downloadArchiveToFile(file:PdfS3UploadedFile):Promise<File> {
		let result = await Axios.get(`${this.url}/archives/${file.ID}/download`);
		let url = result.data.Url;
		result = await axios.get(url, {responseType: "blob"});
		return new File([result.data], file.FileName, {type: result.data.type});
	}

	public async getArchive(id: number): Promise<PdfS3UploadedFile> {
		let result = await Axios.get(`${this.url}/archives/${id}`);
		return new PdfS3UploadedFile(result.data);
	}

	public async getArchives(): Promise<PdfS3UploadedFile[]> {
		let result = await Axios.get(`${this.url}/archives`);
		return result.data.map((f: any) => new PdfS3UploadedFile(f));
	}

	public async getArchiveFromPublicUrl(dossierId: number, key: string): Promise<IPublicUblFile> {
		let result = await axios.get(`${this.publicUrl}/public-link/${dossierId}/${key}`);
		return {
			fileInfo: new PdfS3UploadedFile(result.data.fileInfo),
			userName: result.data.userName,
			companyName: result.data.companyName
		};
	}

	public async downloadArchiveFromPublicUrl(dossierId: number, file: PdfS3UploadedFile) {
		let result = await axios.get(`${this.publicUrl}/public-link/${dossierId}/${file.PublicKey}/download`, );
		let url = result.data.Url;
		downloadUrl(url, file.FileName);
	}
}

export const PdfArchiveService = new PdfArchiveServiceClass();