import { i18n } from "@/setup/i18n-setup";
import { TranslateResult } from "vue-i18n";

export class Job {
	public Name: string;
	public Error: string;
	public DisableApp: boolean;
	public UserID: number;
	public StatusMessage:string = "";
	public StatusMessageData:string[] = [];
	public Progress:number = -1;
	public Cancellable:boolean = false;
	public ShouldCancel:boolean = false;
	public isRunning:boolean = false;
	public ProgressPart:number = 1;
	public ProgressMaxParts:number = 1;

	constructor(data: any) {
		this.Name = data.Name;
		this.Error = data.Error;
		this.DisableApp = data.DisableApp;
		this.UserID = data.UserID;
		this.StatusMessage = data.StatusMessage;
		this.StatusMessageData = data.StatusMessageData;
		this.Progress = data.Progress;
		this.Cancellable = data.Cancellable;
		this.ShouldCancel = data.ShouldCancel;
		this.ProgressPart = data.ProgressPart || 1;
		this.ProgressMaxParts = data.ProgressMaxParts || 1;
	}

	getID():string {
		return this.Name + "_" + this.UserID;
	}

	public getStatusMessage():string | TranslateResult{
		if (this.ShouldCancel) {
			return i18n.t("common.job-is-being-cancelled");
		}
		if (this.StatusMessage == "") {
			return "";
		}
		if (this.Name.startsWith("generate-report")){
			return "";
		}
		return i18n.t(`common.job-status-messages.${this.StatusMessage}`, this.StatusMessageData);
	}

	public hasProgressBar():boolean {
		return this.Progress != -1;
	}

	public getProgressBarValue():number {
		let baseValue = (this.ProgressPart - 1) / this.ProgressMaxParts;
		let currentValue = this.Progress / this.ProgressMaxParts;
		return (baseValue + currentValue) * 100;
	}

	public getName():string{
		if (this.Name.startsWith("generate-report")){
			return i18n.t(`common.job-name.generate-report`, {name: this.StatusMessageData[0] || "custom-report"}).toString();
		}
		if (this.Name.startsWith("script-")){
			return this.Name.substring("script-".length);
		}
		if (this.Name.startsWith("update-product-prices-by-with-composition-product-")){
			let sku = this.Name.split("update-product-prices-by-with-composition-product-")[1];
			return i18n.t(`common.job-name.update-product-prices-by-with-composition-product`, {sku}).toString();
		}
		if (this.Name.startsWith("send-sales")){
			return i18n.t("common.job-name.send-sales").toString();
		}
		let key = `common.job-name.${this.Name}`;
		let name = i18n.t(key).toString();
		if (name == key){
			return this.Name;
		}
		return name;
	}

}
