
import { Product } from "@/models/base";
import Vue from "vue";
import AppProductEditLabelsStockLocation from "./ProductEditLabelsStockLocation.vue";

export default Vue.extend({
	props: {
		product: {
			type: Object as ()=>Product,
			required: true
		}
	},
	components: {
		AppProductEditLabelsStockLocation
	},
	i18nEx: {
		componentPrefix: "components.products"
	}
});
