import { ModelPropertyType, IModelProperty, IModelPropertyClickAction } from "@/utils/models/model-field";
import { getModelByConstructor } from "@/utils/models/model";
import { TextIfValue } from "../report-engine-settings/text-if-value";

function getPropertyByModelConstructorAndPropName(modelConstructor:Function, prop:PropertyKey):IModelProperty{
	let model = getModelByConstructor(modelConstructor, true);
	let field = model.fields[prop.toString()];
	if (!field){
		field = {
			type: "number"
		} as IModelProperty;
		model.fields[prop.toString()] = field;
	}
	return field;
}

export function field(typeName:ModelPropertyType, modelName?:string):PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		if (typeName == "has-one" || typeName == "has-many"){
			if (!modelName){
				throw new Error(`${typeName} required a modelName in class ${target.constructor.name} key ${prop.toString()}`);
			}
		}
		field.type = typeName;
		field.modelName = modelName;
		if (field.type == "number") {
			field.canBeFilteredNummerically = true;
		}
	};
}

export function canBeFilteredNummerically():PropertyDecorator {
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.canBeFilteredNummerically = true;
	};
}

export function computed():PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.isComputed = true;
		field.notFilterable = true;
	};
}

export function hidden():PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.hidden = true;
	};
}

export function rightAlign():PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.rightAlign = true;
	};
}

export function nullable():PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.nullable = true;
	};
}

export function displayClasses(classes:string[]):PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.displayClasses = classes;
	};
}

export function print(printFunction:(value:any, highestParent:any, lowestParent:any)=>string):PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.print = printFunction;
	};
}

export function decimals(count:number):PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.decimals = count;
	};
}

export function translatedName(func:()=>string):PropertyDecorator{
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.getTranslatedName = func;
	};
}

export function dependencies(dependencies:string[]){
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.dependencies = dependencies;
	};
}

export function notFilterable(){
	return function(target:Object, prop:PropertyKey) {
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.notFilterable = true;
	};
}

export type CustomFilterValueInput = "Journal" | "SaleType" | "BookYear" | "Creator" | "Contact" | "Product" | "CountryCode" | "SellPriceCategory" | "BankKind" | "Car" | "VatRegime" | "PurchaseVatRegime" | "ContactSupplier" | "CrmStatus" | "TaskStatus" | "Sale";

export function filterValueInput(input:CustomFilterValueInput){
	return function(target:Object, prop:PropertyKey) {
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.filterValueInput = input;
	};
}

export function filterOnly(){
	return function(target:Object, prop:PropertyKey) {
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.filterOnly = true;
	};
}

export function defaultTextIfValueSettings(handler:()=>Promise<TextIfValue[]>){
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.defaultTextIfValueSettings = handler;
	};
}

export function onClick(action:IModelPropertyClickAction){
	return function(target:Object, prop:PropertyKey){
		let field = getPropertyByModelConstructorAndPropName(target.constructor, prop);
		field.clickAction = action;
	};
}