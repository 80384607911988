import ContactAddress from "./contact-address";
import ContactExtraFields from "./contact-extra-fields";
import ContactGroup from "./contact-group";
import { getModel } from "@/utils/models/model";
import { ignoredFields, priorityFields, model, namedFilters } from "@/utils/models/decorator-model";
import {
	field,
	nullable,
	print,
	hidden,
	filterValueInput,
	filterOnly,
	computed,
	defaultTextIfValueSettings,
	dependencies,
	onClick,
	canBeFilteredNummerically
} from "@/utils/models/decorator-field";
import { DossierConfigService } from "@/services/dossier-config-service";
import { printBoolean, printUser, sellPriceCategory } from "@/utils/pretty-print";
import { DossierService } from "@/services/dossier-service";
import { HumanFilter, HumanFilterOperation } from "@/utils/human-filter";
import { ContactAddressNamedFilter } from "@/utils/models/contact-address-named-filter";
import { NamedFilter } from "@/utils/models/named-filter";
import { TranslateResult } from "vue-i18n";
import { ContactAwsDocument } from "./contact-aws-document";
import { ContactPriceRule } from "./contact-price-rule";
import { LedgerAccount } from "../ledger-account";
import { i18n } from "@/setup/i18n-setup";
import { createUserTextIfValueSettings } from "@/utils/models/default-text-if-value-settings";
import { ISendMessageDialogContact, splitMails } from "@/utils/mail";
import { AllContactsService } from "@/services";
import { AwsDocument } from "./aws-document";
import { getOpenAwsDocumentsFunc } from "@/utils/models/click-action-handlers";

export enum ContactType {
	Customer = 1,
	Supplier = 2,
	Prospect = 3
}

@model("Contact")
@ignoredFields(["MainAddress.Contact", "FriendlyID"])
@priorityFields(["ComputedFriendlyID", ...getModel("ContactAddress").getPriorityFields().map((f) => "MainAddress." + f)])
@namedFilters(Contact.getContactNamedFilters())
export default class Contact{
	@field("number")
	@filterValueInput("Contact")
	@filterOnly()
	public ID:number = 0;

	@field("string")
	public Info:string = "";

	@field("boolean")
	@print(printBoolean)
	public Active:boolean = true;

	@field("boolean")
	public Disabled:boolean = true;

	@field("string")
	@nullable()
	public Vat:string | null = null;

	@field("string")
	@nullable()
	public CommercialVat:string | null = null;

	@field("number")
	public VatRegime:number = 1;

	@field("number")
	public GroupID:number = 0;

	@field("number")
	public MainAddressID:number = 0;

	@field("number")
	public PaymentDays:number = 30;

	@field("date")
	@print((val:Date)=>val.toLocaleDateString("nl-be", { year: "numeric", month: "2-digit", day: "2-digit" }))
	public TimeCreated:Date = new Date();

	@field("date")
	@print((val:Date)=>val.toLocaleDateString("nl-be", { year: "numeric", month: "2-digit", day: "2-digit" }))
	public TimeChanged:Date = new Date();

	@field("number")
	@print(sellPriceCategory)
	@filterValueInput("SellPriceCategory")
	public SellPriceCategory:number = 1;

	@field("boolean")
	@print(printBoolean)
	public PaymentEndMonth:boolean = false;

	@field("has-one", "ContactGroup")
	@nullable()
	public Group:ContactGroup | null = new ContactGroup();

	@field("has-one", "ContactAddress")
	@nullable()
	public MainAddress:ContactAddress | null = new ContactAddress();

	@field("has-many", "ContactAddress")
	public Addresses:ContactAddress[] = [];

	@field("number")
	@hidden()
	public FriendlyID:number = 0;

	@field("string")
	public ComputedFriendlyID:string = "";

	@field("string")
	public Iban:string = "";

	@field("string")
	public Bic:string = "";

	@field("number")
	public Discount:number = 0;

	@field("number")
	public DiscountCash:number = 0;

	@field("number")
	public DiscountCashDays:number = 8;

	@field("boolean")
	@print(printBoolean)
	public Blocked:boolean = false;

	@field("number")
	public MaxReminder:number = 5;

	@field("number")
	public CurrencyID:number = 1;

	@field("number")
	public ExtraFieldsID:number = 0;

	@field("has-one", "ContactExtraFields")
	public ExtraFields:ContactExtraFields = new ContactExtraFields();

	@field("number")
	public DefaultJournalID:number = 1;

	@field("number")
	@filterValueInput("Creator")
	@print(printUser)
	@defaultTextIfValueSettings(createUserTextIfValueSettings("DefaultCreatorID"))
	public DefaultCreatorID:number = 0;

	@field("number")
	@print(printUser)
	@filterValueInput("Creator")
	@defaultTextIfValueSettings(createUserTextIfValueSettings("CreatorID"))
	public CreatorID:number = 0;

	@field("string")
	public MollieCustomerID:string = "";


	@field("string")
	@computed()
	@hidden()
	public get Creator():string {
		let user = DossierService.getUser(this.CreatorID);
		return user.getUserString();
	}

	@field("string")
	@computed()
	@onClick({
		getName(){
			return i18n.t(`models.friendlyNames.Contact.CallMe`).toString();
		},
		canBeUsed(){
			return true;
		},
		handler(target:Object, prop:PropertyKey){
			let parts = prop.toString().split(".");
			parts.pop();
			for (let part of parts) {
				target = (target as any)[part] as Object;
				if (!target) return;
			}
			console.log(target);
			let phone = (target as Contact).getCleanPhone();
			if (!phone) return;
			let el = document.createElement("a");
			el.href = phone;
			el.click();
			el.remove();
		},
		cannotBeDisabled: true
	})
	@dependencies(["MainAddress"])
	public get CallMe():string {
		if (!this.MainAddress) return "";
		if (!this.MainAddress.Phone) return "";
		return `<span style='color:#1c86f0'>` + i18n.t(`models.friendlyNames.Contact.CallMe`).toString() + `</span>`;
	//	let p = this.getCleanPhone();
	//	return this.MainAddress?.Phone ? "<a href='" + p + "' style='text-decoration: none; color:#1c86f0'>"+ i18n.t(`models.friendlyNames.Contact.CallMe`).toString() +"</a>" : "";
	}

	@field("number")
	@computed()
	@dependencies(["Addresses"])
	public get AddressCount():number{
		return this.Addresses.length;
	}

	@field("string")
	@canBeFilteredNummerically()
	public DefaultLedgerAccountCode:string = "";
	@field("has-one", "LedgerAccount")
	public DefaultLedgerAccount:LedgerAccount | null = null;

	public AWSDocuments:ContactAwsDocument[] = [];
	public PriceRules:ContactPriceRule[] = [];
	public UnprocessedFiles: File[] = [];
	public toDeleteFiles:ContactAwsDocument[] = [];

	@field("number")
	@filterValueInput("Contact")
	@filterOnly()
	@hidden()
	public ProspectBecameAClientWithID:number = 0;

	@field("number")
	@filterValueInput("Contact")
	@filterOnly()
	@hidden()
	public ClientCameFromProspectWithID:number = 0;

	@field("boolean")
	@print(printBoolean)
	@onClick(getOpenAwsDocumentsFunc(async function(value:any):Promise<AwsDocument[]>{
		let result = await AllContactsService.getContactByID(value.ID);
		return result.AWSDocuments;
	}))
	public HasAttachedDocument:boolean = false;

	// eslint-disable-next-line max-lines-per-function
	public constructor(data?:any) {
		if (!data) {
			this.PaymentDays = DossierConfigService.getContactsConfig().DefaultPaymentDays;
			this.DiscountCashDays = DossierConfigService.getContactsConfig().DefaultDiscountCashDays;
			return;
		}
		this.ID = data.ID;
		this.Active = data.Active;
		this.Disabled = !data.Active;
		this.Info = data.Info;
		this.Vat = data.Vat;
		this.CommercialVat = data.CommercialVat;
		this.VatRegime = data.VatRegime;
		this.GroupID = data.GroupID;
		this.MainAddressID = data.MainAddressID;
		this.PaymentDays = data.PaymentDays;
		this.PaymentEndMonth = data.PaymentEndMonth || false;
		this.TimeCreated =  new Date(data.TimeCreated);
		this.TimeChanged = new Date(data.TimeChanged);
		this.SellPriceCategory = data.SellPriceCategory;
		this.FriendlyID = data.FriendlyID;
		this.ComputedFriendlyID = data.ComputedFriendlyID;
		this.Iban = data.Iban;
		this.Bic = data.Bic;
		this.Discount = data.Discount;
		this.DiscountCash = data.DiscountCash;
		this.DiscountCashDays = data.DiscountCashDays || 0;
		this.Blocked = data.Blocked || false;
		this.MaxReminder = data.MaxReminder;
		this.CurrencyID = data.CurrencyID;
		this.ExtraFieldsID = data.ExtraFieldsID;
		this.DefaultJournalID = data.DefaultJournalID;
		this.DefaultCreatorID = data.DefaultCreatorID;
		this.CreatorID = data.CreatorID;
		this.MollieCustomerID = data.MollieCustomerID;

		if (data.Group) {
			this.Group = new ContactGroup(data.Group);
		}

		if (data.MainAddress) {
			this.MainAddress = new ContactAddress(data.MainAddress);
		}

		if (data.Addresses) {
			this.Addresses = data.Addresses.map((a: any) => new ContactAddress(a));
			this.Addresses.forEach(a=>{
				if (a.IsMainAddress){
					this.MainAddress = a;
				}
			});
		}

		if (data.ExtraFields) {
			this.ExtraFields = new ContactExtraFields(data.ExtraFields);
			this.ExtraFields.contactGroupId = this.GroupID;
		}

		if (this.Addresses.indexOf(this.MainAddress as any) == -1){
			let mainIndex = this.Addresses.findIndex((a) => a.ID == this.MainAddressID);
			if (mainIndex != -1) {
				this.MainAddress = this.Addresses[mainIndex];
			} else if (this.MainAddress) {
				this.Addresses.push(this.MainAddress);
			}
		}

		if (data.AWSDocuments) {
			this.AWSDocuments = (data.AWSDocuments as any[]).map(d=>new ContactAwsDocument(d));
		}

		if (data.PriceRules) {
			this.PriceRules = (data.PriceRules as any[]).map((r:any)=>new ContactPriceRule(r));
		}

		this.DefaultLedgerAccountCode = data.DefaultLedgerAccountCode || "";
		if (data.DefaultLedgerAccount){
			this.DefaultLedgerAccount = new LedgerAccount(data.DefaultLedgerAccount);
		}

		this.ProspectBecameAClientWithID = data.ProspectBecameAClientWithID || 0;
		this.ClientCameFromProspectWithID = data.ClientCameFromProspectWithID || 0;
		this.HasAttachedDocument = data.HasAttachedDocument;
	}


	// eslint-disable-next-line max-lines-per-function
	public getJSON(): any {
		return {
			ID: this.ID,
			Active: this.Active,
			Disabled: this.Disabled,
			Info: this.Info,
			CommercialVat: this.CommercialVat,
			Vat: this.Vat,
			VatRegime: this.VatRegime,
			GroupID: this.GroupID,
			TimeCreated: this.TimeCreated.toJSON(),
			TimeChanged: this.TimeChanged.toJSON(),
			MainAddressID: this.MainAddressID,
			PaymentDays: this.PaymentDays,
			PaymentEndMonth: this.PaymentEndMonth,
			SellPriceCategory: this.SellPriceCategory,
			FriendlyID: this.FriendlyID,
			ComputedFriendlyID: this.ComputedFriendlyID,
			Iban: this.Iban,
			Bic: this.Bic,
			Discount: this.Discount,
			DiscountCash: this.DiscountCash,
			DiscountCashDays: this.DiscountCashDays,
			Blocked: this.Blocked,
			MaxReminder: this.MaxReminder,
			CurrencyID: this.CurrencyID,
			MainAddress: this.MainAddress ? this.MainAddress.getJSON() : null,
			Addresses: this.Addresses.map(a => a.getJSON()),
			ExtraFieldsID: this.ExtraFieldsID,
			ExtraFields: this.ExtraFields ? this.ExtraFields.getJSON() : null,
			DefaultJournalID: this.DefaultJournalID,
			DefaultCreatorID: this.DefaultCreatorID,
			CreatorID: this.CreatorID,
			MollieCustomerID: this.MollieCustomerID,
			PriceRules: (this.PriceRules || []).map(r=>r.getJSON()).sort((a,b):number=>{
				return a.DisplayOrder - b.DisplayOrder;
			}),
			DefaultLedgerAccountCode: this.DefaultLedgerAccountCode || "",
			ProspectBecameAClientWithID: this.ProspectBecameAClientWithID,
			ClientCameFromProspectWithID: this.ClientCameFromProspectWithID,
			HasAttachedDocument: this.HasAttachedDocument
		};
	};

	public setMainAddress() {
		if (!this.MainAddressID) {
			return;
		}

		let v = this.Addresses.find((v) => v.ID == this.MainAddressID);
		if (v) {
			this.MainAddress = v;
		}
	};

	public getDisplayName():string {
		if (this.MainAddress) {
			if (this.MainAddress.CompanyName){
				return this.MainAddress.CompanyName;
			}
			if (this.MainAddress.FullName){
				return this.MainAddress.FullName;
			}
		}
		return this.ComputedFriendlyID;
	}

	public getCleanPhone():string {
		if (this.MainAddress) {
			return "tel:" + this.MainAddress.Phone.replace(/\D/g,"");
		}
		return "";
	}

	public getCleanMobile():string {
		if (this.MainAddress) {
			return "tel:" + this.MainAddress.Mobile.replace(/\D/g,"");
		}
		return "";
	}

	public getPersonalName():string {
		if (this.MainAddress) {
			if (this.MainAddress.FirstName && this.MainAddress.CompanyName){
				return this.MainAddress.FirstName + ", " + this.MainAddress.CompanyName;
			}
			if (this.MainAddress.CompanyName){
				return this.MainAddress.CompanyName;
			}
		}
		return "";
	}

	private static getContactAddressNamedFilterFieldNames():string[] {
		return [
			"CompanyName",
			"LastName",
			"FirstName",
			"AddressLine1",
			"AddressLine2",
			"Postal",
			"Email",
			"Phone",
			"Mobile",
			"Fax",
			"City",
			"Lng",
			"CountryCode",
			"Reference",
			"CompanyOrFullName",
			"FullName",
			"FullNameReverse",
			"PostalAndCity",
		];
	}

	public static getContactNamedFilters():NamedFilter[] {
		let fields = Contact.getContactAddressNamedFilterFieldNames();
		let model = getModel("ContactAddress");
		let result:NamedFilter[] = fields.map(f=>{
			let namedFilter = new ContactAddressNamedFilter({
				name: `address-field-${f}`,
				filterToString(filter:HumanFilter):TranslateResult {
					let newFilter = new HumanFilter({
						Field: `this.${f}`,
						Operator: filter.Options[0] as HumanFilterOperation,
						Values: filter.Values, IsOr: filter.Options[1] == "true"
					});
					return i18n.t("models.friendlyNames.Contact.namedFilterHasAddressWithField", {field: newFilter.getTranslatedText(model)});
				},
				canBeUsedAsDefaultViewFilter: true
			});
			namedFilter.fieldName = f;
			return namedFilter;
		});
		result.unshift(new NamedFilter({
			name: "is-particulier",
			filterToString(filter:HumanFilter):TranslateResult {
				return "is-particulier";
			},
			canBeUsedAsDefaultViewFilter: false,
			componentName: "custom-checkbox-filter"
		}));
		return result;
	}

	public getVatCountryCodeAndNumberSeperated():{countryCode:string, number:string} {
		if (!this.Vat || this.Vat.length < 2) {
			return {countryCode: "", number: ""};
		}
		let countryCode = this.Vat.substring(0,2);
		if (countryCode.match(/^[a-zA-Z]{2}$/)){
			return {countryCode: this.Vat.substring(0,2), number: this.Vat.substring(2)};
		}
		return {countryCode: "", number: this.Vat};
	}

	public getSendMessageDialogContacts():ISendMessageDialogContact[]{
		let result:ISendMessageDialogContact[] = [];
		for (let address of this.Addresses) {
			let mails = splitMails(address.Email);
			result.push(...(mails.map(m=>{
				return {name: address.getDisplayedName(), value: m };
			})));
		}
		return result.filter(r=>r.value.trim() != "");
	}

	public autoFillBic(){

		if(!this.Iban || this.Bic != "")
			return;

		const countryCode = this.Iban.replace(/\s+/g, "").substring(0, 2);

		let bankCode = "";
		if (countryCode === "BE") {
			bankCode = this.Iban.replace(" ","").substring(4, 7);
		} else if (countryCode === "NL") {
			bankCode = this.Iban.replace(" ","").substring(4, 8);
		}

		const bicMapping = require(`@/assets/json/bic-mappings.json`) as any;
		const bicCode = bicMapping[bankCode];

		if (!bicCode) {
			return false;
		}

		this.Bic = bicCode;
	}
}