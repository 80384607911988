import { pxToMm } from "@/utils/screen";
import { DocumentLayoutClientHeading } from "../documents/layout-client-heading";
import { DocumentLayoutHeading } from "../documents/layout-heading";
import { SaleLayoutAttachment } from "./attachment";
import { SaleLayoutCol } from "./col";
import { FreeBlock } from "../documents/free-block";
import { SaleLayoutFreeLine } from "./free-line";
import { SaleLayoutLogo, SaleLayoutLogoPosition } from "./logo";
import { SaleLayoutPageSettings } from "./page-settings";
import { SaleRequiremenets } from "./requirements";
import { SaleLayoutTableLine } from "./table-line";
import { SaleLayoutTextSettings } from "./text-settings";
import { SaleLayoutTotalLines } from "./total-lines";
import { EditorSettings } from "@/utils/sale-layouts/editor-settings";
import { SignatureSettings } from "./signature-settings";
import { Margin } from "../documents/margin";
import { ClientPortalQrSettings } from "./client-portal-qr-settings";
import { PaymentQrSettings } from "./payment-qr-settings";
import { ILayoutImage } from "../layout-editor";
import { SaleLayoutDetailImage } from "./detail-image";
import { SaleLayoutBarcode } from "./barcode";
import { SaleLayoutPaymentsSettings } from "./payments";
import { PageNumberSettings } from "./page-number-settings";


export class SaleLayout {
	public PageSettings = new SaleLayoutPageSettings();
	public Heading = new DocumentLayoutHeading();
	public ClientHeading = new DocumentLayoutClientHeading();
	public DeliveryAddressHeading = new DocumentLayoutClientHeading();
	public TextSettings = new SaleLayoutTextSettings();
	public Logos = [] as SaleLayoutLogo[];
	public Barcodes = [] as SaleLayoutBarcode[];
	public DetailBeforeImage:SaleLayoutDetailImage = new SaleLayoutDetailImage();
	public DetailAfterImage:SaleLayoutDetailImage = new SaleLayoutDetailImage();
	public Cols = [] as SaleLayoutCol[];
	public TotalLines = new SaleLayoutTotalLines();
	public Requirements = new SaleRequiremenets();
	public TitleLine = [] as SaleLayout [];
	public DateLine = [] as SaleLayoutTableLine[];
	public FreeLines = [] as SaleLayoutFreeLine[];
	public FreeBlocks = [] as FreeBlock[];
	public Attachment = null as null | SaleLayoutAttachment;
	public EditorSettings = new EditorSettings();
	public FileName = "{Type}_{NR}"
	public SignatureSettings = new SignatureSettings();
	public FootMargin = new Margin();
	public ClientPortalQrSettings = new ClientPortalQrSettings();
	public PaymentQrSettings = new PaymentQrSettings();
	public Payments = new SaleLayoutPaymentsSettings();
	public PageNumberSettings  = new PageNumberSettings();
	public UseChromium:boolean = false;

	// eslint-disable-next-line max-lines-per-function
	constructor(data?: any) {
		if (!data) return;
		this.PageSettings = new SaleLayoutPageSettings(data.PageSettings);
		this.Heading = new DocumentLayoutHeading(data.Heading);
		this.ClientHeading = new DocumentLayoutClientHeading(data.ClientHeading);
		this.DeliveryAddressHeading = new DocumentLayoutClientHeading(data.DeliveryAddressHeading);
		this.TextSettings = new SaleLayoutTextSettings(data.TextSettings);
		this.Cols = (data.Cols || []).map((c:any)=>new SaleLayoutCol(c));
		this.Barcodes = (data.Barcodes || []).map((b:any)=>new SaleLayoutBarcode(b));
		this.Logos = (data.Logos || []).map((l: any) => new SaleLayoutLogo(l));
		this.TotalLines = new SaleLayoutTotalLines(data.TotalLines);
		this.FreeBlocks = (data.FreeBlocks || []).map((l:any)=>new FreeBlock(l));
		this.Requirements = new SaleRequiremenets(data.Requirements);
		this.FileName = data.FileName;
		this.EditorSettings = new EditorSettings(data.EditorSettings);
		if (data.TitleLine) {
			this.TitleLine = data.TitleLine.map((t: any) => new SaleLayoutTableLine(t));
		}
		if (data.DateLine) {
			this.DateLine = data.DateLine.map((t: any) => new SaleLayoutTableLine(t));
		}
		if (data.FreeLines) {
			this.FreeLines = data.FreeLines.map((f: any) => new SaleLayoutFreeLine(f));
		}
		if (data.Attachment){
			this.Attachment = new SaleLayoutAttachment(data.Attachment);
		}
		if (data.SignatureSettings) {
			this.SignatureSettings = new SignatureSettings(data.SignatureSettings);
		}
		if (data.ClientPortalQrSettings) {
			this.ClientPortalQrSettings = new ClientPortalQrSettings(data.ClientPortalQrSettings);
		}
		if (data.PaymentQrSettings) {
			this.PaymentQrSettings = new PaymentQrSettings(data.PaymentQrSettings);
		}
		if (data.DetailBeforeImage) {
			this.DetailBeforeImage = new SaleLayoutDetailImage(data.DetailBeforeImage);
		}
		if (data.DetailAfterImage){
			this.DetailAfterImage = new SaleLayoutDetailImage(data.DetailAfterImage);
		}
		if (data.Payments){
			this.Payments = new SaleLayoutPaymentsSettings(data.Payments);
		}
		this.PageNumberSettings = new PageNumberSettings(data.PageNumberSettings);
		this.FootMargin = new Margin(data.FootMargin);
		this.UseChromium = data.UseChromium;
	}

	public getJSON(): any {
		return {
			PageSettings: this.PageSettings.getJSON(),
			Heading: this.Heading.getJSON(),
			ClientHeading: this.ClientHeading.getJSON(),
			DeliveryAddressHeading: this.DeliveryAddressHeading.getJSON(),
			TextSettings: this.TextSettings.getJSON(),
			Logos: this.Logos.map((l) => l.getJSON()),
			Cols: this.Cols.map((l)=>l.getJSON()),
			Barcodes: this.Barcodes.map((b)=>b.getJSON()),
			TotalLines: this.TotalLines.getJSON(),
			Requirements: this.Requirements.getJSON(),
			TitleLine: this.TitleLine.map((t) => t.getJSON()),
			DateLine: this.DateLine.map((d) => d.getJSON()),
			FreeLines: this.FreeLines.map((f) => f.getJSON()),
			FreeBlocks: this.FreeBlocks.map((f)=>f.getJSON()),
			Attachment: this.Attachment ? this.Attachment.getJSON() : null,
			FileName: this.FileName,
			EditorSettings: this.EditorSettings.getJSON(),
			SignatureSettings: this.SignatureSettings.getJSON(),
			FootMargin: this.FootMargin,
			ClientPortalQrSettings: this.ClientPortalQrSettings.getJSON(),
			PaymentQrSettings: this.PaymentQrSettings.getJSON(),
			DetailBeforeImage: this.DetailBeforeImage.getJSON(),
			DetailAfterImage: this.DetailAfterImage.getJSON(),
			Payments: this.Payments.getJSON(),
			PageNumberSettings: this.PageNumberSettings.getJSON(),
			UseChromium: this.UseChromium,
		};
	}

	public getComputedTopMargin(): number {
		return pxToMm(this.PageSettings.HeadingHeight) - this.PageSettings.MarginTop;
	}

	public getLogosByPlacement(placement:SaleLayoutLogoPosition):ILayoutImage[]{
		let logos:ILayoutImage[] = this.Logos.filter(l=>l.Placement == placement);
		if (this.PaymentQrSettings.Placement == placement && this.PaymentQrSettings.Show) {
			logos.push(this.PaymentQrSettings);
		}
		if (this.ClientPortalQrSettings.Placement == placement && this.ClientPortalQrSettings.Show) {
			logos.push(this.ClientPortalQrSettings);
		}
		if (this.SignatureSettings.Placement == placement && this.SignatureSettings.Show) {
			logos.push(this.SignatureSettings);
		}
		for (let barcode of this.Barcodes) {
			if (barcode.Placement == placement) {
				logos.push(barcode);
			}
		}
		return logos;
	}

	public getFreeBlocksByPlacement(placement:SaleLayoutLogoPosition):FreeBlock[] {
		return this.FreeBlocks.filter(b=>b.Placement == placement);
	}

	public logoPlacementRemoved(placement:SaleLayoutLogoPosition){
		for (let i = this.Logos.length -1; i >=0;i--) {
			if (this.Logos[i].Placement == placement) {
				this.Logos.splice(i,1);
			}
		}
		for (let i = this.FreeBlocks.length -1; i >=0;i--) {
			if (this.FreeBlocks[i].Placement == placement) {
				this.FreeBlocks.splice(i,1);
			}
		}
		if (this.PaymentQrSettings.Placement == placement) {
			this.PaymentQrSettings.Show = false;
		}
		if (this.ClientPortalQrSettings.Placement == placement) {
			this.PaymentQrSettings.Show = false;
		}
		if (this.SignatureSettings.Placement == placement) {
			this.SignatureSettings.Show = false;
		}
		for (let i = this.Barcodes.length - 1; i>=0;i--){
			if (this.Barcodes[i].Placement == placement) {
				this.Barcodes.splice(i,1);
			}
		}
	}


	public getRequirementsPageSettings():SaleLayoutPageSettings{
		let pageSettings = new SaleLayoutPageSettings(this.PageSettings.getJSON());
		pageSettings.MarginBottom = this.Requirements.Style.MarginBottom;
		pageSettings.MarginTop = this.Requirements.Style.MarginTop;
		pageSettings.MarginLeft = this.Requirements.Style.MarginLeft;
		pageSettings.MarginRight = this.Requirements.Style.MarginRight;
		if (this.Requirements.DontPrintBackgroundImageOnRequirements) {
			pageSettings.BackgroundImage = "";
		}
		return pageSettings;
	}
}

