
import { Sale } from "@/models/base";
import { SaleService } from "@/services/sale-service";
import Vue from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
	data() {
		return {
			input: "",
			foundSales: [] as Sale[],
		};
	},
	props: {
		value: {
			type: Object as () => Sale
		},
		fancy: Boolean,
		label: [String, Object as ()=>TranslateResult],
		showRemoveButton: Boolean
	},
	computed: {
		icon():string | undefined{
			if (this.showRemoveButton) {
				return "times";
			}
			return undefined;
		}
	},
	watch: {
		async input(input: string) {
			if (input.match(/^\s*$/)) {
				this.foundSales = [];
				return;
			}
			this.foundSales = await SaleService.search(input);
		},
		value: {
			immediate: true,
			handler(sale:Sale) {
				if (this.value) {
					this.input = sale.ComputedFriendlyID;
				} else {
					this.input = "";
				}
			}
		}
	},
	methods: {
		async selectSale(sale:Sale) {
			sale = await SaleService.getSale(sale.ID);
			this.$emit("input", sale);
		},
		onBlur() {
			this.foundSales = [];
			if (this.value) {
				this.input = this.value.ComputedFriendlyID;
			}
			this.$emit("blur");
		},
		focus() {
			(this.$refs.input as any).focus();
		},
		blur() {
			(this.$refs.input as any).blur();
		},
		removeSale(){
			this.$emit("input", null);
		}
	}
});
