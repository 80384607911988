import { HumanFilter } from "../human-filter";
import { DashboardChartData } from "./dashboard-chart-data";

export class DashboardChartDataWithBanks extends DashboardChartData{
	useToAddBanks:boolean = false;
	toAddBanksFilters:HumanFilter[] = [];
	useToSubtractBanks:boolean = false;
	toSubtractBanksFilters:HumanFilter[] = [];

	public constructor(data?:any){
		super(data);
		if (!data) return;
		this.useToAddBanks = data.useToAddBanks;
		this.useToSubtractBanks = data.useToSubtractBanks;
		if (data.toAddBanksFilters) {
			this.toAddBanksFilters = (data.toAddBanksFilters || []).map((d:any)=>new HumanFilter(d));
		}
		if (data.toSubtractBanksFilters){
			this.toSubtractBanksFilters = (data.toSubtractBanksFilters || []).map((d:any)=>new HumanFilter(d));
		}
	}

	public getJSON(){
		return {
			...super.getJSON(),
			useToAddBanks: this.useToAddBanks,
			toAddBanksFilters: this.toAddBanksFilters.map(f=>f.getJSON()),
			useToSubtractBanks: this.useToSubtractBanks,
			toSubtractBanksFilters: this.toSubtractBanksFilters.map(f=>f.getJSON()),
		};
	}
}