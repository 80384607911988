import isoLangsRaw from "@/assets/json/iso-langs.json";
import { i18n } from "@/setup/i18n-setup";
export type IsoLang = {name:string, nativeName:string};
export type IsoLangMap = Record<string, IsoLang>;
export const isoLangs = isoLangsRaw as IsoLangMap;

export type LanguageNamesDict = {[key:string]:string};

export async function getTranslatedIsoLangNames(locale:string = i18n.locale):Promise<LanguageNamesDict>{
	return import(`@/assets/json/languages/${locale}.json`);
}

export function getTranslatedIsoLangName(lng:string, languageNames:LanguageNamesDict):string {
	return languageNames[lng] as string || isoLangs[lng].nativeName || lng;
}