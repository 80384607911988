import { PageSettings } from "./page-settings";
import { TextBlock } from "./text-block";
import { getModel, Model } from "../models/model";
import { removeDuplicatesFromStringList } from "../strings";
import { BrowseService } from "@/services";
import { Barcode } from "./barcode";
import { QrCode } from "./qr-code";
import { Image } from "./image";
import { LabelLayoutModel } from "@/models/label-layout/label-layout-model";

export class LabelLayout {
	BrowseID:number = 0;
	PageSettings:PageSettings = new PageSettings();
	TextBlocks:TextBlock[] = [];
	Barcodes:Barcode[] = [];
	QrCodes:QrCode[] = [];
	Preloads:string[] = [];
	Images:Image[] = [];

	public constructor(data?:any){
		if (!data) return;
		this.BrowseID = data.BrowseID;
		this.PageSettings = new PageSettings(data.PageSettings);
		this.TextBlocks = (data.TextBlocks || []).map((b:any)=>new TextBlock(b));
		this.Barcodes = (data.Barcodes || []).map((b:any)=>new Barcode(b));
		this.QrCodes = (data.QrCodes || []).map((b:any)=>new QrCode(b));
		this.Images = (data.Images || []).map((b:any)=>new Image(b));
		this.Preloads = [...(data.Preloads || [])];
	}

	private computeRecord(record:any):string{
		let result = `<div class="label">`;
		for (let block of this.TextBlocks) {
			result += block.compute(record);
		}
		for (let barcode of this.Barcodes) {
			result += barcode.compute(record);
		}
		for (let qrcode of this.QrCodes) {
			result += qrcode.compute(record);
		}
		for (let image of this.Images) {
			result += image.compute(record);
		}
		result += "</div>";
		return result;
	}

	// eslint-disable-next-line max-lines-per-function
	print(records:any[], layout:LabelLayoutModel){
		let result = `<!DOCTYPE html>
<html lang="en">
<head>
	<meta charset="UTF-8">
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<script src="https://cdn.jsdelivr.net/npm/jsbarcode@3.11.6/dist/JsBarcode.all.min.js" type="text/javascript"></script>
	<script src="${window.location.origin}/qrcode.min.js" type="text/javascript"></script>
	<title>Document</title>

	<style>
	html, body, .page  {
		width: ${this.PageSettings.PageWidth}mm;
		height: ${this.PageSettings.PageHeight}mm;
		padding: 0;
		margin:0;
		outline:none;
		border:none;
		box-sizing: border-box;
	}

	html, body, .page, .page>.row{
		overflow:visible;
		white-space:nowrap;
	}

	.page, .page>.row, .label {
		position:relative;
		display:block;
		padding: 0;
		margin:0;
		outline:none;
		border:none;
		box-sizing: border-box;
	}

	.page {
		page-break-after: always;
		padding-left: ${this.PageSettings.Margin.Left}mm;
		padding-right: ${this.PageSettings.Margin.Right}mm;
		padding-top: ${this.PageSettings.Margin.Top}mm;
		padding-bottom: ${this.PageSettings.Margin.Bottom}mm;
	}

	.label {
		display: inline-block;
		width: ${this.PageSettings.Width}mm;
		height: ${this.PageSettings.Height}mm;
		margin-right: ${this.PageSettings.HorizontalSpacing}mm;
		white-space:normal;
		overflow:hidden;
	}

	.label:last-child{
		margin-right:0;
	}

	.row:not(:last-child){
		margin-bottom:${this.PageSettings.VerticalSpacing}mm;
	}

	.block{
		position:absolute;
	}

	.barcode-container, .qrcode-container, .image-container {
		box-sizing:border-box;
		position:absolute;
		background-size: contain;
		background-repeat: no-repeat;
		pointer-events:all;
	}

	.barcode, .qrcode-container> img, .image-container> img  {
		max-width:100%;
		max-height:100%;
		width:100%;
		height:100%;
	}

	.qrcode-container> img, .image-container> img  {
		object-fit:contain;
	}
	</style>

	<script>
		window.addEventListener("load", ()=>{
			try{
				JsBarcode(".barcode").init();
			}catch(err){
			}
			let qrCodes = document.getElementsByClassName("qrcode-container");
			for (let code of qrCodes) {
				try{
					let value = code.getAttribute("data-value");
					new QRCode(code, value);
				}catch(err){
				}
			}
			${layout.getPrintScriptCode(this.PageSettings.PageWidth, this.PageSettings.PageHeight)}
		})
	</script>
</head>
<body>`;
		let rows = 0;
		let cols = 0;

		for (let record of records){
			if (rows == 0 && cols == 0){
				result += `\n<div class="page">`;
			}
			if (cols == 0){
				result += `\n<div class="row">`;
			}
			result += `\n${this.computeRecord(record)}`;
			cols++;
			if (cols % this.PageSettings.LabelsPerRow == 0) {
				cols = 0;
				rows++;
				result += `\n</div>`;
			}
			if (rows % this.PageSettings.RowsPerPage == 0 && rows != 0) {
				rows = 0;
				result += `\n</div>`;
			}
		}

		if (cols != 0) {
			result += "\n</div>";
		}
		if (rows != 0) {
			result += `\n</div>`;
		}
		result += `\n</body>\n</html>`;

		layout.print(result);
	}

	private computedPreloads(model:Model){
		let preloads:string[] = [];
		for (let block of this.TextBlocks){
			preloads = preloads.concat(block.getPreloads(model));
		}

		removeDuplicatesFromStringList(preloads);
	}

	getModel():Model{
		let browse = BrowseService.getBrowseWithoutViewSettings(this.BrowseID);
		if (!browse) throw Error("Browse does not exist");
		return getModel(browse.Table);
	}

	public getJSON(){
		let model = this.getModel();
		this.computedPreloads(model);
		return {
			BrowseID: this.BrowseID,
			PageSettings: this.PageSettings.getJSON(),
			TextBlocks: this.TextBlocks.map(t=>t.getJSON()),
			Barcodes: this.Barcodes.map(b=>b.getJSON()),
			QrCodes: this.QrCodes.map(b=>b.getJSON()),
			Images: this.Images.map(b=>b.getJSON()),
			Preloads: [...this.Preloads]
		};
	}
}