import { ServerConfig } from "@/config/config";
import { Job } from "@/models/job";
import { Product } from "@/models/base";
import { StockIn } from "@/models/base";
import { Axios } from "@/utils/axios";
import { ReportService } from "@/services/report-service";
import { advancedSearch } from "@/utils/browse/browse";
import { IGetResponse } from "@/utils/get-response";
import { HumanFilter } from "@/utils/human-filter";
import { IPaginationData, IQueryParams, IQueryParamsWithOptionalView } from "@/utils/query-params";
import { View } from "@/models/view";
import { FetchedTotal } from "@/utils/views/fetched-total";
import { StockMovement } from "@/models/base/stock-movement";
import { getModel } from "@/utils/models/model";

export interface IGetStockMovementResponse extends IGetResponse<StockMovement>{
	totals:number[];
}

export class StockServiceClass{
	readonly inUrl:string = ServerConfig.host + "/stock-in";
	readonly stockLocationUrl:string = ServerConfig.host + "/stock-location";
	readonly stockMovementUrl:string = ServerConfig.host + "/stock-movement";

	async getSettings() {
		let result = await Axios.get(ServerConfig.host + "/browse/5", {
			params: {}
		});
		return result.data;
	}

	async getStock(query: IQueryParamsWithOptionalView):Promise<IGetResponse<StockIn>> {
		let result = await advancedSearch(query, this.inUrl, getModel("StockIn"));
		result.data.data = result.data.data.map((s: any) => new StockIn(s));
		return result.data;
	}

	async getStocksByIds(ids: number[]): Promise<StockIn[]> {
		let result = await Axios.get(this.inUrl, {
			params: {
				limit: ids.length,
				filters: [
					{
						Field: "this.ID",
						Operator: "in",
						Values: ids.map(id => id + "")
					}
				] as HumanFilter[],
			}
		});
		return result.data.data.map((s: any) => new StockIn(s));
	}

	async search(queryString: string, query: IQueryParams): Promise<IGetResponse<StockIn>> {
		let result = await Axios.get(this.inUrl + "/view/" + query.view.ID, {
			params: {
				limit: query.limit,
				ofsfset: query.offset,
				order: query.orderBy,
				orderDirection: query.orderDirection,
				filters: [
					new HumanFilter({
						Field: "this.Sku",
						Operator: "like",
						Values: [queryString]
					}),
				] as HumanFilter[]
			}
		});
		result.data.data = result.data.data.map((s: any) => new StockIn(s));
		return result.data;
	}

	async getTotals(view:View):Promise<FetchedTotal[]>{
		let result = await Axios.get(`${this.inUrl}/view/${view.ID}/totals`);
		return (result.data || []).map((d:any)=>new FetchedTotal(d));
	}

	async delStock(stock: StockIn[]): Promise<void> {
		await Axios.delete(this.inUrl, {
			data: stock.map(p => p.ID)
		});
	}

	async saveStock(stock: StockIn[]): Promise<StockIn[]> {
		let result = await Axios.post(this.inUrl, stock.map(s => s.getJSON()));
		return result.data.map((s: any) => new StockIn(s));
	}

	async generateStockTransferReport(product:Product, startTime:Date, endTime:Date):Promise<Job> {
		let result = await Axios.post(`${this.inUrl}/reports/${product.ID}/stock-movement`, {
			BeginDate: Math.round(startTime.getTime() / 1000),
			EndDate: Math.round(endTime.getTime() / 1000)
		});
		let job = new Job(result.data);
		ReportService.notifyUserReportQueued(job);
		return job;
	}

	async computeStock(maxDate?:Date, productIds?:number[]):Promise<Job> {
		let data = {
			MaxDate: maxDate,
			ProductIDs: productIds
		};
		let result = await Axios.post(`${this.inUrl}/compute-stock`, data);
		return new Job(result.data);
	}

	async emptyStock(locationId:number):Promise<Job>{
		let result = await Axios.post(`${this.inUrl}/empty-stock/${locationId}`);
		return new Job(result.data);
	}

	async getStockMovement(productId:number, pagination:IPaginationData, fromDate?:Date, tillDate?:Date, saleNr?:string):Promise<IGetStockMovementResponse>{
		let params:any = {
			limit: pagination.limit,
			offset: pagination.offset,
			order: pagination.orderBy,
			orderDirection: pagination.orderDirection,
		};
		if (fromDate) {
			params.from = fromDate.toJSON();
		}
		if (tillDate) {
			params.till = tillDate.toJSON();
		}
		if (saleNr) {
			params.saleNr = saleNr;
		}
		let result = await Axios.get(`${this.stockMovementUrl}/${productId}`, {params});
		result.data.data = result.data.data.map((s: any) => new StockMovement(s));
		return result.data;
	}
};

export const StockService = new StockServiceClass();
