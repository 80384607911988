import { TextStyledProperty } from "../documents/text-styled-property";

export class Group {
	GroupByProps:string[] = [];
	OrderASC:boolean = false;
	Style:TextStyledProperty = Group.getDefaultStyle();
	HideTitle:boolean = false;
	CustomText:string = "";
	CreateNewPage:boolean = false;

	constructor(data?:any) {
		if (!data) return;
		if (data.GroupByProp) {
			this.GroupByProps = [data.GroupByProp];
		}
		if (data.GroupByProps) {
			this.GroupByProps = [...data.GroupByProps];
		}
		if (data.Style) {
			this.Style = new TextStyledProperty(data.Style);
		}
		this.OrderASC = data.OrderASC;
		this.HideTitle = data.HideTitle;
		this.CustomText = data.CustomText;
		this.CreateNewPage = data.CreateNewPage;
	}

	getJSON():any {
		return {
			GroupByProps: [...this.GroupByProps],
			OrderASC: this.OrderASC,
			Style: this.Style.getJSON(),
			HideTitle: this.HideTitle,
			CustomText: this.CustomText,
			CreateNewPage: this.CreateNewPage
		};
	}

	static getDefaultStyle():TextStyledProperty {
		let style = new TextStyledProperty();
		style.BackgroundColor = "#dcdcdc";
		return style;
	}
}