import { CustomTranslation } from "@/utils/custom-translation";
export class ClientPortalTabConfig{
	ID: String = "";
	TypeID: Number = 0;
	Name :CustomTranslation = new CustomTranslation();
	Position :Number = 0;
	ShowOpenInSeperateWindow: Boolean = false;
	ShowToSignInSeperateWindow: Boolean = false;
	SeperateWindowTitle:CustomTranslation = new CustomTranslation();
	Deletable: Boolean = true;
	Visible: Boolean = true;
	Info: String = ""
	ShowInOverview: Boolean = false;

	public constructor(data?:any){
		if (!data) return;
		this.ID = data.ID;
		this.TypeID = data.TypeID;
		this.Name = new CustomTranslation(data.Name);
		this.Position = data.Position;
		this.ShowOpenInSeperateWindow = data.ShowOpenInSeperateWindow;
		this.ShowToSignInSeperateWindow = data.ShowToSignInSeperateWindow;
		this.SeperateWindowTitle =  new CustomTranslation(data.SeperateWindowTitle);
		this.Deletable = data.Deletable;
		this.Visible = data.Visible;
		this.Info = data.Info;
		this.ShowInOverview = data.ShowInOverview;
	}

	public getJSON(){
		return {
			ID: this.ID,
			TypeID: this.TypeID,
			Name: this.Name.getJSON(),
			Position: this.Position,
			ShowOpenInSeperateWindow: this.ShowOpenInSeperateWindow,
			ShowToSignInSeperateWindow: this.ShowToSignInSeperateWindow,
			Deletable: this.Deletable,
			SeperateWindowTitle: this.SeperateWindowTitle,
			Visible: this.Visible,
			Info: this.Info,
			ShowInOverview: this.ShowInOverview
		};
	}
}