import { BrowserInfo } from "./browser";
import {v4 as uuid} from "uuid";
import { ElectronFunctions } from "./electron/electron";

// eslint-disable-next-line max-lines-per-function
export async function printPdf(data:Blob, fileName:string) {
	if (BrowserInfo.isElectron()) {
		await ElectronFunctions.printTempFileWithDialog(data, fileName);
		return;
	}
	var url = URL.createObjectURL(data);

	let iframe = window.document.createElement("iframe") as HTMLIFrameElement;
	iframe.width = "100%";
	iframe.height = "100%";
	if (!BrowserInfo.isChrome() && !BrowserInfo.isSafari() && !BrowserInfo.isFirefox()) {
		iframe.setAttribute("sandbox", "allow-popups allow-scripts allow-same-origin allow-modals " +
				"allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation");
	}
	let frameId = uuid();
	iframe.id = frameId;
	iframe.src = url;
	iframe.name = "PRINTFRAME";
	iframe.style.border = "none";
	iframe.style.position = "absolute";
	iframe.style.display = "block";
	iframe.style.top = "100%";
	window.document.body.appendChild(iframe);
	//if (BrowserInfo.isElectron()){
	//	await triggerIframePrintElectron(iframe);
	//	await ElectronFunctions.deleteTempFile(fileName);
	//}else{
	await triggerIframePrint(iframe);
	URL.revokeObjectURL(url);
	//}
}
/*
async function triggerIframePrintElectron(iframe:HTMLIFrameElement){
	await new Promise<void>((resolve)=>{
		let handler = async (event:any)=>{
			if (event.source != iframe.contentWindow) return;
			if (event.data != "printready") return;
			if (!iframe.contentWindow) return;
			async function eventHandler() {
				iframe.remove();
				resolve();
				window.removeEventListener("mousemove", eventHandler);
			}
			setTimeout(()=>{
				window.addEventListener("mousemove",eventHandler);
			}, 1000);
			window.removeEventListener("message", handler);
		};
		window.addEventListener("message", handler);
	});
}
*/
async function triggerIframePrint(iframe:HTMLIFrameElement){
	await new Promise<void>((resolve)=>{
		iframe.addEventListener("load", function () {
			iframe.focus();
			if (!iframe.contentWindow) return;
			setTimeout(() => {
				if (!iframe.contentWindow) return;
				iframe.contentWindow.print();
				async function eventHandler() {
					iframe.remove();
					if (!BrowserInfo.isElectron()){
						resolve();
						window.removeEventListener("mousemove", eventHandler);
					}
				}
				let timeoutDuration = 1000;
				//if (BrowserInfo.isElectron()){
				//	timeoutDuration = 60000*5;
				//	resolve();
				//}
				setTimeout(()=>{
					window.addEventListener("mousemove",eventHandler);
				}, timeoutDuration);
			}, 1000);
		});
	});
}


export async function printHtml(html:string){
	let iframe = document.createElement("iframe") as HTMLIFrameElement;
	iframe.src = `data:text/html;charset=utf-8,${encodeURIComponent(html)}`;
	iframe.width = "100%";
	iframe.height = "100%";
	if (!BrowserInfo.isChrome() && !BrowserInfo.isSafari()) {
		iframe.setAttribute("sandbox", "allow-popups allow-scripts allow-same-origin allow-modals " +
				"allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation");
	}
	let frameId = uuid();
	iframe.id = frameId;
	iframe.name = "PRINTFRAME";
	iframe.style.border = "none";
	iframe.style.position = "absolute";
	iframe.style.display = "block";
	iframe.style.top = "100%";
	await new Promise<void>((resolve, reject)=>{
		iframe.addEventListener("message", (event)=>{
			let ev = event as MessageEvent;
			if (ev.data == "close"){
				resolve();
				iframe.remove();
			}
		});
		window.document.body.appendChild(iframe);
	});
}