import { field } from "@/utils/models/decorator-field";
import { model } from "@/utils/models/decorator-model";

@model("ProductLabelSettings")
export class ProductLabelSettings {
	ProductID:number = 0;

	@field("number")
	ToPrintForStockLocation1:number = -1;
	@field("number")
	ToPrintForStockLocation2:number =-1;
	@field("number")
	ToPrintForStockLocation3:number =-1;
	@field("number")
	ToPrintForStockLocation4:number =-1;
	@field("number")
	ToPrintForStockLocation5:number =-1;
	@field("number")
	ToPrintForStockLocation6:number =-1;
	@field("number")
	ToPrintForStockLocation7:number =-1;
	@field("number")
	ToPrintForStockLocation8:number =-1;
	@field("number")
	ToPrintForStockLocation9:number =-1;
	@field("number")
	ToPrintForStockLocation10:number =-1;

	@field("number")
	IsOneLabelProduct:boolean = false;

	ToUseLabelLayoutForLocation1:number = 0;
	ToUseLabelLayoutForLocation2:number = 0;
	ToUseLabelLayoutForLocation3:number = 0;
	ToUseLabelLayoutForLocation4:number = 0;
	ToUseLabelLayoutForLocation5:number = 0;
	ToUseLabelLayoutForLocation6:number = 0;
	ToUseLabelLayoutForLocation7:number = 0;
	ToUseLabelLayoutForLocation8:number = 0;
	ToUseLabelLayoutForLocation9:number = 0;
	ToUseLabelLayoutForLocation10:number = 0;


	public constructor(data?:any){
		if (!data) return;
		this.ProductID = data.ProductID;
		this.ToPrintForStockLocation1 = data.ToPrintForStockLocation1;
		this.ToPrintForStockLocation2 = data.ToPrintForStockLocation2;
		this.ToPrintForStockLocation3 = data.ToPrintForStockLocation3;
		this.ToPrintForStockLocation4 = data.ToPrintForStockLocation4;
		this.ToPrintForStockLocation5 = data.ToPrintForStockLocation5;
		this.ToPrintForStockLocation6 = data.ToPrintForStockLocation6;
		this.ToPrintForStockLocation7 = data.ToPrintForStockLocation7;
		this.ToPrintForStockLocation8 = data.ToPrintForStockLocation8;
		this.ToPrintForStockLocation9 = data.ToPrintForStockLocation9;
		this.ToPrintForStockLocation10 = data.ToPrintForStockLocation10;
		this.IsOneLabelProduct = data.IsOneLabelProduct;
		this.ToUseLabelLayoutForLocation1 = data.ToUseLabelLayoutForLocation1;
		this.ToUseLabelLayoutForLocation2 = data.ToUseLabelLayoutForLocation2;
		this.ToUseLabelLayoutForLocation3 = data.ToUseLabelLayoutForLocation3;
		this.ToUseLabelLayoutForLocation4 = data.ToUseLabelLayoutForLocation4;
		this.ToUseLabelLayoutForLocation5 = data.ToUseLabelLayoutForLocation5;
		this.ToUseLabelLayoutForLocation6 = data.ToUseLabelLayoutForLocation6;
		this.ToUseLabelLayoutForLocation7 = data.ToUseLabelLayoutForLocation7;
		this.ToUseLabelLayoutForLocation8 = data.ToUseLabelLayoutForLocation8;
		this.ToUseLabelLayoutForLocation9 = data.ToUseLabelLayoutForLocation9;
		this.ToUseLabelLayoutForLocation10 = data.ToUseLabelLayoutForLocation10;
	}

	public getJSON(){
		return {
			ProductID: this.ProductID,
			ToPrintForStockLocation1: this.ToPrintForStockLocation1,
			ToPrintForStockLocation2: this.ToPrintForStockLocation2,
			ToPrintForStockLocation3: this.ToPrintForStockLocation3,
			ToPrintForStockLocation4: this.ToPrintForStockLocation4,
			ToPrintForStockLocation5: this.ToPrintForStockLocation5,
			ToPrintForStockLocation6: this.ToPrintForStockLocation6,
			ToPrintForStockLocation7: this.ToPrintForStockLocation7,
			ToPrintForStockLocation8: this.ToPrintForStockLocation8,
			ToPrintForStockLocation9: this.ToPrintForStockLocation9,
			ToPrintForStockLocation10: this.ToPrintForStockLocation10,
			IsOneLabelProduct: this.IsOneLabelProduct,
			ToUseLabelLayoutForLocation1: this.ToUseLabelLayoutForLocation1,
			ToUseLabelLayoutForLocation2: this.ToUseLabelLayoutForLocation2,
			ToUseLabelLayoutForLocation3: this.ToUseLabelLayoutForLocation3,
			ToUseLabelLayoutForLocation4: this.ToUseLabelLayoutForLocation4,
			ToUseLabelLayoutForLocation5: this.ToUseLabelLayoutForLocation5,
			ToUseLabelLayoutForLocation6: this.ToUseLabelLayoutForLocation6,
			ToUseLabelLayoutForLocation7: this.ToUseLabelLayoutForLocation7,
			ToUseLabelLayoutForLocation8: this.ToUseLabelLayoutForLocation8,
			ToUseLabelLayoutForLocation9: this.ToUseLabelLayoutForLocation9,
			ToUseLabelLayoutForLocation10: this.ToUseLabelLayoutForLocation10,
		};
	}

	public getAmountInLocation(locationId:number):number {
		let value = this[`ToPrintForStockLocation${locationId}` as keyof ProductLabelSettings] as number || 0;
		if(this.IsOneLabelProduct && value > 0){
			value = 1;
		}
		return value;
	}

	public getLabelLayoutIdOfLocation(locationId:number):number {
		return this[`ToUseLabelLayoutForLocation${locationId}` as keyof ProductLabelSettings] as number || 0;
	}

}